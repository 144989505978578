<template>
  <svg
    :width="width"
    :height="height"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 22 22"
  >
    <path
      d="M11.0026 20.1673C16.0652 20.1673 20.1693 16.0633 20.1693 11.0007C20.1693 5.93804 16.0652 1.83398 11.0026 1.83398C5.93999 1.83398 1.83594 5.93804 1.83594 11.0007C1.83594 16.0633 5.93999 20.1673 11.0026 20.1673Z"
      :stroke="iconColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></path>
    <path
      d="M7.33594 12.834C7.33594 12.834 8.71094 14.6673 11.0026 14.6673C13.2943 14.6673 14.6693 12.834 14.6693 12.834"
      :stroke="iconColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></path>
    <path
      d="M8.25 8.25H8.26"
      :stroke="iconColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></path>
    <path
      d="M13.75 8.25H13.76"
      :stroke="iconColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></path>
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 26,
    },
    height: {
      type: [Number, String],
      default: 26,
    },
    iconColor: {
      type: String,
      default: "#c4c4c4",
    },
  },
};
</script>