<template>
  <div class="w-full lg:w-10/12 px-4 font-proximaMedium">
    <h2 class="mt-8 mb-4 text-left text-lg font-bold text-subheaderText">
      Pets
    </h2>
    <ul
      class="w-full px-14 py-6 bg-white rounded-md flex flex-wrap items-center justify-between"
    >
      <li
        v-for="(data, i) in pets"
        :key="i"
        class="flex flex-col items-center justify-center cursor-pointer"
        @click="gotoDiaryView(data)"
      >
        <img
          class="mb-2 rounded-full w-24 h-24"
          :src="data.picture ? data.picture : picture(data)"
          :alt="data.picture"
        />
        <span>{{ data.callName + "-" }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
import { PassThrough } from "stream";
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      careBlogsByUser: "appointment/getCareBlogs",
    }),
    pets() {
      var pets = [];
      //care_blog_entries
      console.log("///////", this.careBlogsByUser);
      this.careBlogsByUser.map((item) =>
        item.pets_profiles.map((pet) =>
          pets.findIndex((x) => x.petId == pet.petId) === -1
            ? pets.push(pet)
            : ""
        )
      );
      console.log("hhhh", pets);
      return pets;
    },
  },
  created() {
    this.getCareBlogsByUser();
  },
  methods: {
    ...mapActions({
      getCareBlogsByUser: "appointment/getCareBlogsByUser",
    }),
    picture(pet) {
      if (pet.species == 1)
        return require(`@/assets/images/peticons/dog_icon.png`);
      if (pet.species == 2)
        return require(`@/assets/images/peticons/cat_icon.png`);
      if (pet.species == 3)
        return require(`@/assets/images/peticons/ferret_icon.png`);
      if (pet.species == 4)
        return require(`@/assets/images/peticons/bird_icon.png`);
      if (pet.species == 5)
        return require(`@/assets/images/peticons/other_icon.png`);
    },
    gotoDiaryView(pet) {
      this.$router.push({ name: "PetownerDiaryView", params: { data: pet } });
    },
  },
};
</script>

<style></style>
