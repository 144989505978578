<template>
  <div class="my-6">
    <DashboardSection />
    <NavMenuSecondary page="PetownerDiaryView" />
  </div>
</template>

<script>
import DashboardSection from "../components/petownerdiary/dashboard/DashboardSection.vue";
import NavMenuSecondary from "../components/navigationmenu/secondary/NavMenuSecondary.vue";

export default {
  components: {
    DashboardSection,
    NavMenuSecondary
  },
};
</script>