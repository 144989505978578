<template>
  <div>
    <!-- add video modals -->
    <ModalsConfirm :show="addVideoModals" @close="addVideoModals = false">
      <div slot="actionQuestion" class="relative">
        <div
          class="
            absolute
            -top-6
            -right-6
            text-lg
            font-proximaSemibold
            cursor-pointer
          "
          @click="closeVideoModals"
        >
          ✖
        </div>
        <img
          class="m-auto"
          src="@/assets/images/videocam-icon.png"
          alt="videocam-icon"
        />
        <h1 class="mt-4 text-2xl">Record or upload video message</h1>
      </div>
      <div
        slot="actionText"
        class="
          mb-6
          flex flex-col
          items-center
          justify-center
          text-sm
          font-proximaLight
        "
      >
        <p>
          Up to 60 seconds can be recorded, a limit of 15MB is permitted for
          uploads.
        </p>
      </div>
      <div slot="leftBtn" class="mx-auto w-10/12 relative">
        <PaperclipIcon
          class="absolute top-3 left-5"
          width="24px"
          height="24px"
        />
        <SubmitButton
          class="pl-7 mx-auto"
          marginT="mt-2"
          :title="$t('global_upload_video')"
          background="bg-primary"
          paddingX="px-4"
          width="w-full"
          @click.native="openAddNewVideoModals"
        />
      </div>
      <div slot="rightBtn" class="mx-auto w-10/12 relative">
        <VideoIcon class="absolute top-3 left-6" width="24px" height="24px" />
        <SubmitButton
          class="pl-7 mx-auto"
          marginT="mt-2"
          :title="$t('add_voice_record_new')"
          background="bg-primary"
          paddingX="px-4"
          width="w-full"
        />
      </div>
    </ModalsConfirm>
    <!-- add video modals end -->
    <!-- add media modals -->
    <ModalsConfirm :show="addMediaModals" @close="addMediaModals = false">
      <div slot="actionQuestion" class="relative">
        <div
          class="
            absolute
            -top-6
            -right-6
            text-lg
            font-proximaSemibold
            cursor-pointer
          "
          @click="closeMediaModals"
        >
          ✖
        </div>
        <img
          class="m-auto"
          src="@/assets/images/media-icon.png"
          alt="media-icon"
        />
        <h1 class="mt-4 text-2xl">{{$t('global_add_media')}}</h1>
      </div>
      <div
        slot="actionText"
        class="
          mb-6
          flex flex-col
          items-center
          justify-center
          text-sm
          font-proximaLight
        "
      >
        <p>
          You may take a new photo or upload an existing one from your device (max 5MB per photo).
        </p>
      </div>
      <div slot="leftBtn" class="mx-auto w-10/12 relative">
        <PaperclipIcon
          class="absolute top-3 left-5"
          width="24px"
          height="24px"
        />
        <SubmitButton
          class="pl-7 mx-auto"
          marginT="mt-2"
          :title="$t('global_upload_file')"
          background="bg-primary"
          paddingX="px-4"
          width="w-full"
          @click.native="openAddNewMediaModals"
        />
      </div>
      <div slot="rightBtn" class="mx-auto w-10/12 relative">
        <MediaIcon class="absolute top-3 left-6" width="24px" height="24px" />
        <SubmitButton
          class="pl-7 mx-auto"
          marginT="mt-2"
          :title="$t('global_take_photo')"
          background="bg-primary"
          paddingX="px-4"
          width="w-full"
        />
      </div>
    </ModalsConfirm>
    <!-- add media modals end -->
    <!-- add voice modals -->
    <ModalsConfirm :show="addVoiceModals" @close="addVoiceModals = false">
      <div slot="actionQuestion" class="relative">
        <div
          class="
            absolute
            -top-6
            -right-6
            text-lg
            font-proximaSemibold
            cursor-pointer
          "
          @click="closeVoiceModals"
        >
          ✖
        </div>
        <img class="m-auto" src="@/assets/images/mic-icon.png" alt="mic-icon" />
        <h1 class="mt-4 text-2xl">Record or upload a voice message</h1>
      </div>
      <div
        slot="actionText"
        class="
          mb-6
          flex flex-col
          items-center
          justify-center
          text-sm
          font-proximaLight
        "
      >
        <p>
          Up to 90 seconds can be recorded. A limit of 3MB is permitted for
          uploads.
        </p>
      </div>
      <div slot="leftBtn" class="mx-auto w-10/12 relative">
        <PaperclipIcon
          class="absolute top-3 left-5"
          width="24px"
          height="24px"
        />
        <SubmitButton
          class="pl-7 mx-auto"
          marginT="mt-2"
          :title="$t('global_upload_voice')"
          background="bg-primary"
          paddingX="px-4"
          width="w-full"
          @click.native="openAddNewVoiceModals"
        />
      </div>
      <div slot="rightBtn" class="mx-auto w-10/12 relative">
        <MicIcon class="absolute top-3 left-6" width="24px" height="24px" />
        <SubmitButton
          class="pl-7 mx-auto"
          marginT="mt-2"
          :title="$t('add_voice_record_new')"
          background="bg-primary"
          paddingX="px-4"
          width="w-full"
        />
      </div>
    </ModalsConfirm>
    <!-- add voice modals end -->
  </div>
</template>

<script>
import ModalsConfirm from "../../modals/ModalsConfirm.vue";
import SubmitButton from "../../buttons/SubmitButton.vue";
import VideoIcon from "../../icons/VideoIcon.vue";
import MediaIcon from "../../icons/MediaIcon.vue";
import PaperclipIcon from "../../icons/PaperclipIcon.vue";
import MicIcon from "../../icons/MicIcon.vue";

export default {
  props: ["addVideoModals", "addVoiceModals","addMediaModals"],
  components: {
    SubmitButton,
    ModalsConfirm,
    PaperclipIcon,
    VideoIcon,
    MediaIcon,
    MicIcon,
  },
  data() {
    return {};
  },
  methods: {
    closeVideoModals() {
      this.$emit("closeVideoModals", false);
    },
    closeVoiceModals() {
      this.$emit("closeVoiceModals", false);
    },
    closeMediaModals() {
      this.$emit("closeMediaModals", false);
    },
    openAddNewVideoModals() {
      console.log("------------");
      this.closeVideoModals();
      this.$emit("openAddNewVideoModals", true);
    },
    openAddNewVoiceModals() {
      this.closeVoiceModals();
      this.$emit("openAddNewVoiceModals", true);
    },
    openAddNewMediaModals() {
      this.closeMediaModals();
      this.$emit("openAddNewMediaModals", true);
    },
  },
};
</script>

<style>
</style>