<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 25 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M22.5065 15.043L22.5236 6.04298C22.5244 5.64516 22.3671 5.26333 22.0863 4.98148C21.8056 4.69964 21.4243 4.54088 21.0265 4.54012L19.2685 4.53676C18.0757 4.53383 16.9328 4.0574 16.091 3.21219L14.8484 1.96781C14.5685 1.68668 14.1885 1.52793 13.7918 1.52629L10.2743 1.51957C9.87648 1.51889 9.49471 1.67625 9.21293 1.95704L7.96856 3.19666C7.12323 4.03902 5.97792 4.51111 4.78455 4.50908L3.02655 4.50572C2.62873 4.50496 2.2469 4.66227 1.96505 4.94304C1.68321 5.2238 1.52445 5.60503 1.52369 6.00286L1.50649 15.0028C1.50573 15.4007 1.66304 15.7825 1.9438 16.0643C2.22457 16.3462 2.6058 16.5049 3.00362 16.5057L21.0036 16.5401C21.4014 16.5409 21.7832 16.3836 22.0651 16.1028C22.3469 15.822 22.5057 15.4408 22.5065 15.043ZM3.02942 3.00573C2.23377 3.00421 1.47011 3.31882 0.906423 3.88035C0.34274 4.44188 0.0252117 5.20434 0.0236914 5.99999L0.00649409 15C0.00497375 15.7956 0.319586 16.5593 0.881119 17.123C1.44265 17.6867 2.20511 18.0042 3.00076 18.0057L21.0007 18.0401C21.7964 18.0416 22.56 17.727 23.1237 17.1655C23.6874 16.6039 24.0049 15.8415 24.0064 15.0458L24.0236 6.04585C24.0252 5.2502 23.7106 4.48654 23.149 3.92285C22.5875 3.35917 21.825 3.04164 21.0294 3.04012L19.2714 3.03676C18.4758 3.03507 17.7135 2.71743 17.1521 2.15371L15.9124 0.90934C15.351 0.345618 14.5887 0.0279791 13.7931 0.0262889L10.2771 0.0195705C9.48155 0.0182202 8.71801 0.332944 8.15446 0.894516L6.91009 2.13414C6.34654 2.69571 5.583 3.01044 4.78741 3.00909L3.02942 3.00573Z"
      :fill="iconColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.0101 13.5237C13.0047 13.5256 13.9593 13.1323 14.6639 12.4304C15.3685 11.7285 15.7654 10.7754 15.7673 9.78086C15.7692 8.7863 15.3759 7.83172 14.674 7.12712C13.9721 6.42251 13.019 6.0256 12.0245 6.0237C11.0299 6.0218 10.0753 6.41507 9.37072 7.11698C8.66612 7.8189 8.26921 8.77197 8.26731 9.76653C8.26541 10.7611 8.65867 11.7157 9.36059 12.4203C10.0625 13.1249 11.0156 13.5218 12.0101 13.5237ZM12.0073 15.0237C13.3997 15.0263 14.7361 14.4758 15.7225 13.4931C16.709 12.5104 17.2646 11.1761 17.2673 9.78373C17.27 8.39134 16.7194 7.05493 15.7367 6.06848C14.754 5.08204 13.4197 4.52636 12.0273 4.5237C10.635 4.52104 9.29854 5.07161 8.31209 6.0543C7.32565 7.03698 6.76997 8.37128 6.76731 9.76366C6.76465 11.156 7.31522 12.4925 8.29791 13.4789C9.28059 14.4653 10.6149 15.021 12.0073 15.0237Z"
      :fill="iconColor"
    />
    <path
      d="M4.522 6.75872C4.52162 6.95764 4.44224 7.14825 4.30132 7.28863C4.1604 7.42902 3.96948 7.50767 3.77057 7.50729C3.57166 7.50691 3.38104 7.42753 3.24066 7.28661C3.10028 7.14569 3.02162 6.95477 3.022 6.75586C3.02238 6.55695 3.10177 6.36633 3.24269 6.22595C3.38361 6.08557 3.57452 6.00691 3.77344 6.00729C3.97235 6.00767 4.16296 6.08705 4.30335 6.22798C4.44373 6.3689 4.52238 6.55981 4.522 6.75872Z"
      :fill="iconColor"
    />
  </svg>
</template>
  
  <script>
export default {
  name: "MediaIcon",
  props: {
    width: {
      type: [Number, String],
      default: 18,
    },
    height: {
      type: [Number, String],
      default: 18,
    },
    iconColor: {
      type: String,
      default: "#fff",
    },
  },
};
</script>