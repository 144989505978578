<template>
  <div class="w-max flex items-center justify-center">
    <div @mouseover="hover = true" @mouseleave="hover = false">
      <component
        :is="whichEmoji"
        :iconColor="hoverComp"
        width="32"
        height="32"
        class="mr-2 cursor-pointer"
      />
    </div>
  </div>
</template>

<script>
import EmojiSad from "../icons/emoji/EmojiSad.vue";
import EmojiLaugh from "../icons/emoji/EmojiLaugh.vue";
import EmojiSmile from "../icons/emoji/EmojiSmile.vue";
import EmojiSadSlight from "../icons/emoji/EmojiSadSlight.vue";
import EmojiMeh from "../icons/emoji/EmojiMeh.vue";
import EmojiHeartEyes from "../icons/emoji/EmojiHeartEyes.vue";
import EmojiAngry from "../icons/emoji/EmojiAngry.vue";

export default {
  props: ["whichEmoji", "eachEmoji", "highlightedEmoji"],
  components: {
    EmojiSad,
    EmojiLaugh,
    EmojiSmile,
    EmojiSadSlight,
    EmojiMeh,
    EmojiHeartEyes,
    EmojiAngry,
  },
  data() {
    return {
      hover: false,
    };
  },
  computed: {
    hoverComp() {
      if (this.hover) {
        return "#000";
      } else if (this.highlightedEmoji == this.eachEmoji ) {
        return "#000";
      } else {
        return "#c4c4c4";
      }
    },
  },
  methods: {},
};
</script>

<style>
</style>