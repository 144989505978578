<template>
  <svg
    :width="width"
    :height="height"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="2 2 20 20"
  >
    <path
      d="M9 8.751C9.16706 8.74627 9.33337 8.7751 9.4891 8.83576C9.64483 8.89643 9.78681 8.98771 9.90665 9.10421C10.0265 9.2207 10.1217 9.36004 10.1868 9.51399C10.2518 9.66794 10.2854 9.83337 10.2854 10.0005C10.2854 10.1676 10.2518 10.3331 10.1868 10.487C10.1217 10.641 10.0265 10.7803 9.90665 10.8968C9.78681 11.0133 9.64483 11.1046 9.4891 11.1652C9.33337 11.2259 9.16706 11.2547 9 11.25C8.66848 11.25 8.35054 11.1183 8.11612 10.8839C7.8817 10.6495 7.75 10.3315 7.75 10C7.75 9.66848 7.8817 9.35054 8.11612 9.11612C8.35054 8.8817 8.66848 8.75 9 8.75V8.751ZM15 8.751C15.1671 8.74627 15.3334 8.7751 15.4891 8.83576C15.6448 8.89643 15.7868 8.98771 15.9066 9.10421C16.0265 9.2207 16.1217 9.36004 16.1868 9.51399C16.2518 9.66794 16.2854 9.83337 16.2854 10.0005C16.2854 10.1676 16.2518 10.3331 16.1868 10.487C16.1217 10.641 16.0265 10.7803 15.9066 10.8968C15.7868 11.0133 15.6448 11.1046 15.4891 11.1652C15.3334 11.2259 15.1671 11.2547 15 11.25C14.6747 11.2408 14.3659 11.1051 14.1391 10.8718C13.9122 10.6385 13.7854 10.3259 13.7854 10.0005C13.7854 9.6751 13.9122 9.36253 14.1391 9.1292C14.3659 8.89588 14.6747 8.7602 15 8.751ZM8.25 15C8.05109 15 7.86032 15.079 7.71967 15.2197C7.57902 15.3603 7.5 15.5511 7.5 15.75C7.5 15.9489 7.57902 16.1397 7.71967 16.2803C7.86032 16.421 8.05109 16.5 8.25 16.5H15.75C15.9489 16.5 16.1397 16.421 16.2803 16.2803C16.421 16.1397 16.5 15.9489 16.5 15.75C16.5 15.5511 16.421 15.3603 16.2803 15.2197C16.1397 15.079 15.9489 15 15.75 15H8.25ZM2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22C6.477 22 2 17.523 2 12ZM12 3.5C9.74566 3.5 7.58365 4.39553 5.98959 5.98959C4.39553 7.58365 3.5 9.74566 3.5 12C3.5 14.2543 4.39553 16.4163 5.98959 18.0104C7.58365 19.6045 9.74566 20.5 12 20.5C14.2543 20.5 16.4163 19.6045 18.0104 18.0104C19.6045 16.4163 20.5 14.2543 20.5 12C20.5 9.74566 19.6045 7.58365 18.0104 5.98959C16.4163 4.39553 14.2543 3.5 12 3.5Z"
      :fill="iconColor"
    ></path>
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 26,
    },
    height: {
      type: [Number, String],
      default: 26,
    },
    iconColor: {
      type: String,
      default: "#c4c4c4",
    },
  },
};
</script>