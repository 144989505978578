<template>
  <div>
    <img
      class="mt-4 mb-10 m-auto"
      src="@/assets/images/diary-page-icon.png"
      alt="diary-page-icon"
      v-if="!entryData"
    />
    <div v-else>
      <ul>
        <li
          class="m-6 bg-whiteBlue p-4 pr-20 text-left relative"
          v-for="(data, i) in entryData"
          :key="i"
        >
          <FavoriteComponent :color="'#ff0c00'" />
          <EditIcon
            class="absolute bottom-4 right-4 cursor-pointer"
            iconColor="#0AC0CB"
            @click.native="openEditModals(data)"
          />
          <h5 class="font-proximaMedium">{{ data.name }}</h5>
          <p class="mt-1 font-proximaLight text-sm">{{ data.text }}</p>
          <TagsComponent :tags="data.tags" />
          <div class="flex items-center mt-4 font-proximaMedium text-xs">
            <span>{{ data.date }}</span>
            <span class="mx-6">{{ data.time }}</span>
            <div class="flex items-center">
              <span class="mr-2">Mood:</span>
              <components :is="data.mood" />
            </div>
          </div>
        </li>
      </ul>
    </div>
    <EditNoteModals
      :toggleEditModals="toggleEditModals"
      :data="data"
      :id="id"
      :pets="pets"
      :title="title"
      :description="description"
      :mood="mood"
      :keyword="keyword"
      :petsOwned="petsOwned"
      :relevantPets="relevantPets"
      @closeEditModals="closeEditModals"
    />
  </div>
</template>

<script>
import TagsComponent from "./TagsComponent.vue";
import FavoriteComponent from "./FavoriteComponent.vue";
import EditIcon from "../../icons/EditIcon.vue";
import EmojiAngry from "../../icons/emojiColor/EmojiAngry.vue";
import EmojiHeartEyes from "../../icons/emojiColor/EmojiHeartEyes.vue";
import EmojiLaugh from "../../icons/emojiColor/EmojiLaugh.vue";
import EmojiMeh from "../../icons/emojiColor/EmojiMeh.vue";
import EmojiSad from "../../icons/emojiColor/EmojiSad.vue";
import EmojiSadSlight from "../../icons/emojiColor/EmojiSadSlight.vue";
import EmojiSmile from "../../icons/emojiColor/EmojiSmile.vue";
import EditNoteModals from "../../careblog/modals/EditNoteModals.vue";

export default {
  components: {
    TagsComponent,
    FavoriteComponent,
    EditIcon,
    EmojiAngry,
    EmojiHeartEyes,
    EmojiLaugh,
    EmojiMeh,
    EmojiSad,
    EmojiSadSlight,
    EmojiSmile,
    EditNoteModals,
  },
  data() {
    return {
      entryData: [
        {
          name: "A Sunny Day",
          text: "Today was a beautiful, bright and sunny day so I decided to build a boat! I needed to get some long, hard wood so I travelled to the forest to collect a huge pile. After some time, I began to get hungry. Today was a beautiful, bright and sunny day so I decided to build a boat! I needed to get some long, hard wood so I travelled to the forest to collect a huge pile. After some time, I began to get hungry.",
          tags: ["Sunny", "Wood", "Travel"],
          date: "Friday, 21 May 2022",
          time: "12.00 PM",
          mood: "EmojiLaugh",
        },
      ],
      pets: [],
      toggleEditModals: false,
      title: "",
      description: "",
      relevantPets: [],
      mood: 0,
      keyword: [],
      data: {},
      id: 0,
    };
  },
  methods: {
    openEditModals(i) {
      this.data = i;
      this.pets = i.tags;
      this.title = i.name;
      this.description = i.text;
      this.relevantPets = i.relevantPets;
      this.mood = i.mood;
      this.keyword = i.tags;
      this.toggleEditModals = true;
      this.id = i.id;
    },
    closeEditModals() {
      this.toggleEditModals = false;
    },
  },
};
</script>

<style>
</style>