<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.95312 10.75C2.95313 13.4022 4.00669 15.9457 5.88206 17.8211C7.75742 19.6964 10.301 20.75 12.9531 20.75C15.6053 20.75 18.1488 19.6964 20.0242 17.8211C21.8996 15.9457 22.9531 13.4022 22.9531 10.75C22.9531 8.09784 21.8996 5.5543 20.0242 3.67893C18.1488 1.80357 15.6053 0.75 12.9531 0.75C10.301 0.75 7.75742 1.80357 5.88206 3.67893C4.00669 5.5543 2.95313 8.09784 2.95312 10.75Z"
      fill="#FF6242"
    />
    <path
      d="M12.9531 0.75C10.9753 0.75 9.04192 1.33649 7.39743 2.4353C5.75294 3.53412 4.47121 5.0959 3.71433 6.92317C2.95746 8.75043 2.75942 10.7611 3.14528 12.7009C3.53113 14.6407 4.48354 16.4225 5.88206 17.8211C7.28059 19.2196 9.06242 20.172 11.0022 20.5579C12.942 20.9437 14.9527 20.7457 16.78 19.9888C18.6072 19.2319 20.169 17.9502 21.2678 16.3057C22.3666 14.6612 22.9531 12.7278 22.9531 10.75C22.9531 8.09784 21.8996 5.5543 20.0242 3.67893C18.1488 1.80357 15.6053 0.75 12.9531 0.75ZM12.9531 19.25C11.1484 19.25 9.38415 18.7148 7.88355 17.7122C6.38295 16.7095 5.21338 15.2844 4.52273 13.617C3.83208 11.9496 3.65137 10.1149 4.00346 8.3448C4.35555 6.57472 5.22463 4.9488 6.50078 3.67265C7.77693 2.3965 9.40285 1.52742 11.1729 1.17533C12.943 0.823244 14.7777 1.00395 16.4451 1.6946C18.1125 2.38525 19.5376 3.55482 20.5403 5.05542C21.543 6.55602 22.0781 8.32025 22.0781 10.125C22.0781 12.5451 21.1167 14.8661 19.4055 16.5773C17.6942 18.2886 15.3732 19.25 12.9531 19.25Z"
      fill="#E04122"
    />
    <path
      d="M9.95312 2.75C9.95312 2.94891 10.2692 3.13968 10.8318 3.28033C11.3944 3.42098 12.1575 3.5 12.9531 3.5C13.7488 3.5 14.5118 3.42098 15.0744 3.28033C15.6371 3.13968 15.9531 2.94891 15.9531 2.75C15.9531 2.55109 15.6371 2.36032 15.0744 2.21967C14.5118 2.07902 13.7488 2 12.9531 2C12.1575 2 11.3944 2.07902 10.8318 2.21967C10.2692 2.36032 9.95312 2.55109 9.95312 2.75Z"
      fill="#FF866E"
    />
    <path
      d="M2.95312 10.75C2.95313 13.4022 4.00669 15.9457 5.88206 17.8211C7.75742 19.6964 10.301 20.75 12.9531 20.75C15.6053 20.75 18.1488 19.6964 20.0242 17.8211C21.8996 15.9457 22.9531 13.4022 22.9531 10.75C22.9531 8.09784 21.8996 5.5543 20.0242 3.67893C18.1488 1.80357 15.6053 0.75 12.9531 0.75C10.301 0.75 7.75742 1.80357 5.88206 3.67893C4.00669 5.5543 2.95313 8.09784 2.95312 10.75V10.75Z"
      stroke="#45413C"
      stroke-width="0.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8.20312 11.25C8.20312 11.3826 8.2558 11.5098 8.34957 11.6036C8.44334 11.6973 8.57052 11.75 8.70312 11.75C8.83573 11.75 8.96291 11.6973 9.05668 11.6036C9.15045 11.5098 9.20312 11.3826 9.20312 11.25C9.20312 11.1174 9.15045 10.9902 9.05668 10.8964C8.96291 10.8027 8.83573 10.75 8.70312 10.75C8.57052 10.75 8.44334 10.8027 8.34957 10.8964C8.2558 10.9902 8.20312 11.1174 8.20312 11.25Z"
      fill="#45413C"
      stroke="#45413C"
      stroke-width="0.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.6181 10.5007C10.6181 10.5007 9.11812 8.25072 7.45312 9.00072"
      stroke="#45413C"
      stroke-width="0.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M15.2891 10.5007C15.2891 10.5007 16.7891 8.25072 18.4541 9.00072"
      stroke="#45413C"
      stroke-width="0.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M16.7031 11.25C16.7031 11.3826 16.7558 11.5098 16.8496 11.6036C16.9433 11.6973 17.0705 11.75 17.2031 11.75C17.3357 11.75 17.4629 11.6973 17.5567 11.6036C17.6504 11.5098 17.7031 11.3826 17.7031 11.25C17.7031 11.1174 17.6504 10.9902 17.5567 10.8964C17.4629 10.8027 17.3357 10.75 17.2031 10.75C17.0705 10.75 16.9433 10.8027 16.8496 10.8964C16.7558 10.9902 16.7031 11.1174 16.7031 11.25Z"
      fill="#45413C"
      stroke="#45413C"
      stroke-width="0.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.4531 15.7505C11.1931 15.2561 12.0631 14.9922 12.9531 14.9922C13.8431 14.9922 14.7131 15.2561 15.4531 15.7505"
      stroke="#45413C"
      stroke-width="0.5"
      stroke-linecap="round"
    />
    <path
      d="M16.4131 4.18359C16.3512 4.50934 16.1629 4.79735 15.8894 4.98472C15.6158 5.17209 15.2792 5.24358 14.9531 5.18359"
      stroke="#45413C"
      stroke-width="0.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M18.1544 6.49859C17.9857 6.39606 17.8389 6.26124 17.7225 6.10187C17.606 5.9425 17.5222 5.76172 17.4757 5.56988C17.4293 5.37804 17.4211 5.17892 17.4518 4.98393C17.4824 4.78895 17.5513 4.60193 17.6544 4.43359"
      stroke="#45413C"
      stroke-width="0.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M15.2227 6.38434C15.5734 6.19674 15.9843 6.15614 16.365 6.27147C16.7457 6.3868 17.065 6.64862 17.2527 6.99934"
      stroke="#45413C"
      stroke-width="0.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M20.2031 13.5C20.2031 13.915 19.6431 14.25 18.9531 14.25C18.2631 14.25 17.7031 13.915 17.7031 13.5C17.7031 13.085 18.2631 12.75 18.9531 12.75C19.6431 12.75 20.2031 13.085 20.2031 13.5Z"
      fill="#E04122"
    />
    <path
      d="M5.70312 13.5C5.70312 13.915 6.26313 14.25 6.95312 14.25C7.64313 14.25 8.20312 13.915 8.20312 13.5C8.20312 13.085 7.64313 12.75 6.95312 12.75C6.26313 12.75 5.70312 13.085 5.70312 13.5Z"
      fill="#E04122"
    />
    <path
      opacity="0.15"
      d="M4.95312 22.75C4.95313 22.9489 5.79598 23.1397 7.29627 23.2803C8.79656 23.421 10.8314 23.5 12.9531 23.5C15.0749 23.5 17.1097 23.421 18.61 23.2803C20.1103 23.1397 20.9531 22.9489 20.9531 22.75C20.9531 22.5511 20.1103 22.3603 18.61 22.2197C17.1097 22.079 15.0749 22 12.9531 22C10.8314 22 8.79656 22.079 7.29627 22.2197C5.79598 22.3603 4.95313 22.5511 4.95312 22.75Z"
      fill="#45413C"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 26,
    },
    height: {
      type: [Number, String],
      default: 26,
    },
    iconColor: {
      type: String,
      default: "#c4c4c4",
    },
  },
};
</script>