<template>
  <Transition name="modal">
    <div v-if="compAddDiaryModals" class="modal-mask">
      <div class="modal-wrapper">
        <div
          class="modal-container md:w-10/12 lg:w-6/12 2xl:w-5/12 h-screen flex flex-col justify-center items-center font-proximaMedium overflow-y-auto"
        >
          <div class="">
            <img
              class="m-auto"
              ref="prefetch"
              src="@/assets/images/diary-bg-icon.png"
              alt="diary-icon"
            />
            <h1 class="mt-4 text-2xl">
              {{ $t("add_note_diary_modals_title") }}
            </h1>
            <FormError :errors="errorForm" />
          </div>
          <div class="mt-8 w-full">
            <div
              class="w-full p-2 text-left border-2 border-primary rounded-md"
              @click="togglePets = !togglePets"
            >
              <span v-if="!checkedPet.length" class="text-lightGrey">{{
                $t("global_select_relevant_pets")
              }}</span>
              <ul v-else class="w-full flex justify-start flex-wrap">
                <li
                  class="w-max mr-2 mt-2 text-white bg-primary rounded-md px-3 py-2 relative"
                  v-for="(data, i) in checkedPetName"
                  :key="i"
                >
                  {{ data }}
                  <!-- <div
                    class="
                      bg-white
                      rounded-full
                      absolute
                      -right-1
                      -top-1
                      cursor-pointer
                    "
                  >
                    <TrashIcon iconColor="#0AC0CB" @click.native="removePet = true" />
                  </div> -->
                </li>
              </ul>
            </div>
            <ul
              v-show="togglePets"
              class="bg-lightGrey20 flex flex-wrap justify-evenly rounded-md"
            >
              <PetOwnedList
                v-for="(data, i) in pets"
                :key="i"
                :data="data"
                @addPet="funcAddPet(data)"
                @removePet="funcRemovePet(data)"
              />
            </ul>
          </div>
          <div class="w-full mt-4">
            <h5 class="text-left">{{ $t("global_title") }}</h5>
            <input
              ref="title"
              class="w-full p-2 border-2 border-primary rounded-md"
              type="text"
              v-model="title"
            />
            <FormError :errors="errors.title" />
          </div>
          <div class="w-full mt-4">
            <h5 class="text-left">{{ $t("global_description") }}</h5>
            <textarea
              class="w-full p-2 border-2 border-primary rounded-md"
              cols="30"
              rows="5"
              v-model="description"
            ></textarea>
            <FormError :errors="errors.description" />
          </div>
          <div class="mt-4 w-full flex items-center justify-start">
            <EmojiEach
              v-for="(emoji, i) in emojis"
              :key="i + 1"
              :eachEmoji="i + 1"
              :highlightedEmoji="highlightedEmoji"
              :whichEmoji="emoji.name"
              @click.native="clickIcon(emoji)"
            />
          </div>
          <div class="w-full mt-3">
            <!-- <NewTag
              :tags="this.tags"
              @changeTag="changeTag"
              @deleteTag="deleteTag"
            /> -->
            <!-- <input class="w-full p-2 border-2 border-primary rounded-md" type="text" placeholder="Enter keyword tags, separated by a comma" v-model="tags" /> -->
          </div>
          <div v-if="diary == false" class="w-9/12 mt-4 flex justify-end">
            <div class="mr-3 w-4/12 relative" v-if="!newVideo">
              <VideoIcon
                class="absolute top-3 left-3"
                width="24px"
                height="24px"
              />
              <SubmitButton
                class="pl-8"
                marginT="0"
                :title="$t('global_add_video')"
                background="bg-primary"
                paddingX="px-4"
                width="w-full"
                @click.native="addVideoModals = true"
              />
            </div>
            <div class="mr-3 w-4/12 relative flex items-center" v-else>
              <span class="truncate">{{ newVideoDetails }}</span>
              <div
                class="text-lg font-proximaSemibold cursor-pointer"
                @click="removeNewVideo"
              >
                ✖
              </div>
            </div>
            <div class="mr-3 w-4/12 relative" v-if="!newVoice">
              <MicIcon
                class="absolute top-3 left-3"
                width="24px"
                height="24px"
              />
              <SubmitButton
                class="pl-6"
                marginT="0"
                :title="$t('global_add_voice')"
                background="bg-primary"
                paddingX="px-4"
                width="w-full"
                @click.native="addVoiceModals = true"
              />
            </div>
            <div class="mr-3 w-4/12 relative flex items-center" v-else>
              <span class="truncate">{{ newVoiceDetails }}</span>
              <div
                class="text-lg font-proximaSemibold cursor-pointer"
                @click="removeNewVoice"
              >
                ✖
              </div>
            </div>
            <!-- begin add media -->
            <div class="w-4/12 relative" v-if="!newMedia">
              <MediaIcon
                class="absolute top-3 left-3"
                width="24px"
                height="24px"
              />
              <SubmitButton
                class="pl-8"
                marginT="0"
                :title="$t('global_add_media')"
                background="bg-primary"
                paddingX="px-4"
                width="w-full"
                @click.native="addMediaModals = true"
              />
            </div>
            <div class="w-4/12 relative flex items-center" v-else>
              <span class="truncate">{{ newMediaDetails }}</span>
              <div
                class="text-lg font-proximaSemibold cursor-pointer"
                @click="removeNewMedia"
              >
                ✖
              </div>
            </div>
            <!-- end add media -->
          </div>

          <div class="mt-12 w-full md:w-8/12 flex justify-evenly">
            <div class="mx-auto w-10/12 md:w-5/12 relative">
              <BorderedButton
                class="mx-auto"
                marginT="mt-2"
                :title="$t('global_cancel')"
                paddingX="px-6"
                width="w-full"
                @click.native="closeDiaryModals"
              />
            </div>
            <div class="mx-auto w-10/12 md:w-5/12 relative">
              <SubmitButton
                class="mx-auto"
                marginT="mt-2"
                :title="$t('global_confirm')"
                background="bg-success"
                paddingX="px-6"
                width="w-full"
                @click.native="submitAddCareBlog"
              />
            </div>
          </div>
        </div>
        <AddVoiceVideoModals
          :addVideoModals="addVideoModals"
          :addVoiceModals="addVoiceModals"
          :addMediaModals="addMediaModals"
          @closeVideoModals="closeVideoModals"
          @closeVoiceModals="closeVoiceModals"
          @closeMediaModals="closeMediaModals"
          @openAddNewVideoModals="openAddNewVideoModals"
          @openAddNewVoiceModals="openAddNewVoiceModals"
          @openAddNewMediaModals="openAddNewMediaModals"
        />
        <!-- upload new video modals -->
        <ModalsConfirm
          :show="addNewVideoModals"
          @close="addNewVideoModals = false"
        >
          <div slot="actionQuestion" class="relative">
            <div
              class="absolute -top-6 -right-6 text-lg font-proximaSemibold cursor-pointer"
              @click="addNewVideoModals = false"
            >
              ✖
            </div>
            <VideoComponent
              :newVideo="newVideo"
              v-if="newVideo"
              :key="componentKey"
            />
            <div v-else>
              <VideoIcon
                class="mx-auto"
                width="84px"
                height="84px"
                iconColor="#0AC0CB"
              />
            </div>
            <h1 class="mt-4 text-2xl">{{ $t("global_add_video") + "?" }}</h1>
          </div>
          <div
            slot="actionText"
            class="mb-6 flex flex-col items-center justify-center text-sm font-proximaLight"
          ></div>
          <div slot="leftBtn" class="mx-auto w-10/12 relative">
            <BorderedButton
              class="mx-auto"
              marginT="mt-2"
              :title="$t('global_add_new')"
              paddingX="px-4"
              width="w-full"
              @click.native="addNewVideo"
            />
          </div>
          <div slot="rightBtn" class="mx-auto w-10/12 relative">
            <SubmitButton
              class="mx-auto"
              marginT="mt-2"
              :title="$t('global_confirm')"
              background="bg-success"
              paddingX="px-4"
              width="w-full"
              @click.native="addNewVideoModals = false"
            />
          </div>
        </ModalsConfirm>
        <!-- upload new video modals end -->
        <!-- upload voice modals -->
        <ModalsConfirm
          :show="addNewVoiceModals"
          @close="addNewVoiceModals = false"
        >
          <div slot="actionQuestion" class="relative">
            <div
              class="absolute -top-6 -right-6 text-lg font-proximaSemibold cursor-pointer"
              @click="addNewVoiceModals = false"
            >
              ✖
            </div>
            <div v-if="newVoice">
              <img
                rel="prefetch"
                src="@/assets/images/audio-waves.png"
                alt="audio-waves"
              />
              <span>{{ newVoiceDetails }}</span>
            </div>
            <div v-else>
              <MicIcon
                class="mx-auto"
                width="84px"
                height="84px"
                iconColor="#0AC0CB"
              />
            </div>
            <h1 class="mt-4 text-2xl">{{ $t("global_add_voice") + "?" }}</h1>
          </div>
          <div
            slot="actionText"
            class="mb-6 flex flex-col items-center justify-center text-sm font-proximaLight"
          ></div>
          <div slot="leftBtn" class="mx-auto w-10/12 relative">
            <BorderedButton
              class="mx-auto"
              marginT="mt-2"
              :title="$t('global_add_new')"
              paddingX="px-4"
              width="w-full"
              @click.native="addNewVoice"
            />
          </div>
          <div slot="rightBtn" class="mx-auto w-10/12 relative">
            <SubmitButton
              class="mx-auto"
              marginT="mt-2"
              :title="$t('global_confirm')"
              background="bg-success"
              paddingX="px-4"
              width="w-full"
              @click.native="addNewVoiceModals = false"
            />
          </div>
        </ModalsConfirm>
        <!-- upload voice modals end -->
        <!-- upload media modals -->
        <ModalsConfirm
          :show="addNewMediaModals"
          @close="addNewMediaModals = false"
        >
          <div slot="actionQuestion" class="relative">
            <div
              class="absolute -top-6 -right-6 text-lg font-proximaSemibold cursor-pointer"
              @click="addNewMediaModals = false"
            >
              ✖
            </div>
            <div v-if="newMedia">
              <img
                class="mx-auto"
                width="400"
                rel="prefetch"
                :src="mediaSrc"
                alt="Media"
              />
              <span>{{ newMediaDetails }}</span>
            </div>
            <div v-else>
              <MediaIcon
                class="mx-auto"
                width="84px"
                height="84px"
                iconColor="#0AC0CB"
              />
            </div>
            <h1 class="mt-4 text-2xl">{{ $t("global_add_media") + "?" }}</h1>
          </div>
          <div
            slot="actionText"
            class="mb-6 flex flex-col items-center justify-center text-sm font-proximaLight"
          ></div>
          <div slot="leftBtn" class="mx-auto w-10/12 relative">
            <BorderedButton
              class="mx-auto"
              marginT="mt-2"
              :title="$t('global_add_new')"
              paddingX="px-4"
              width="w-full"
              @click.native="addNewMedia"
            />
          </div>
          <div slot="rightBtn" class="mx-auto w-10/12 relative">
            <SubmitButton
              class="mx-auto"
              marginT="mt-2"
              :title="$t('global_confirm')"
              background="bg-success"
              paddingX="px-4"
              width="w-full"
              @click.native="addNewMediaModals = false"
            />
          </div>
        </ModalsConfirm>
        <!-- upload media modals end -->
      </div>
    </div>
  </Transition>
</template>

<script>
import SubmitButton from "../../buttons/SubmitButton.vue";
import BorderedButton from "../../buttons/BorderedButton.vue";
import PetOwnedList from "./PetOwnedList.vue";
import NewTag from "./NewTag.vue";
import FormError from "../../warning/FormError.vue";
import TrashIcon from "@/components/icons/TrashIcon.vue";
import EmojiEach from "../../emojis/EmojiEach.vue";
import VideoComponent from "../../trainingplan/VideoComponent.vue";
import AddVoiceVideoModals from "../../trainingplan/modals/AddVoiceVideoModals.vue";
import AddIcon from "../../icons/AddIcon.vue";
import MicIcon from "../../icons/MicIcon.vue";
import VideoIcon from "../../icons/VideoIcon.vue";
import MediaIcon from "../../icons/MediaIcon.vue";
import ModalsConfirm from "../../modals/ModalsConfirm.vue";

import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    show: Boolean,
    petsOwned: Array,
    pet: {},
    diary: Boolean,
  },
  components: {
    SubmitButton,
    BorderedButton,
    PetOwnedList,
    TrashIcon,
    EmojiEach,
    FormError,
    NewTag,
    VideoComponent,
    AddVoiceVideoModals,
    AddIcon,
    MicIcon,
    VideoIcon,
    MediaIcon,
    ModalsConfirm,
  },
  data() {
    return {
      addVideoModals: false,
      addVoiceModals: false,
      addMediaModals: false,
      addNewVideoModals: false,
      addNewVoiceModals: false,
      addNewMediaModals: false,
      newVideo: "",
      newVoice: "",
      newMedia: "",
      newVideoDetails: {},
      newVoiceDetails: {},
      newMediaDetails: {},
      togglePets: false,
      checkedPet: [],
      checkedPetName: [],
      highlightedEmoji: 1,
      selectedEmoji: "",
      title: "",
      description: "",
      tags: [],
      emojis: [
        {
          id: "1",
          name: "EmojiHeartEyes",
        },
        {
          id: "2",
          name: "EmojiLaugh",
        },
        {
          id: "3",
          name: "EmojiSmile",
        },
        {
          id: "4",
          name: "EmojiSadSlight",
        },
        {
          id: "5",
          name: "EmojiMeh",
        },
        {
          id: "6",
          name: "EmojiSad",
        },
        {
          id: "7",
          name: "EmojiAngry",
        },
      ],
      errors: {},
    };
  },
  computed: {
    ...mapGetters({
      petRecords: "petRecord/getPetRecords",
    }),
    errorForm() {
      return this.$store.state.errorForm;
    },

    pets() {
      return this.petRecords
        ? this.petRecords.filter((item) => item.type == 1)
        : [];
    },
    compAddDiaryModals() {
      return this.$store.state.careBlogDiaryModalsState;
    },
    mediaSrc() {
      return URL.createObjectURL(this.newMedia);
    },
  },
  watch: {
    compAddDiaryModals: function () {
      this.emptyField();
    },
  },
  created() {
    this.$store.commit("errorForm", "");
    this.getPetRecords(localStorage.getItem("email"));
  },
  methods: {
    ...mapActions({
      addCareBlog: "appointment/addCareBlog",
      getPetRecords: "petRecord/getPetRecords",
    }),

    funcAddPet(i) {
      this.checkedPet.push(i.petID);
      this.checkedPetName.push(i.callName);
    },
    funcRemovePet(i) {
      let item = i.petID;

      const index = this.checkedPet.indexOf(i.petID);
      const index2 = this.checkedPetName.indexOf(i.callName);
      this.checkedPet.splice(index, 1);
      this.checkedPetName.splice(index2, 1);
    },
    clickIcon(emoji, i) {
      console.log(emoji.name);
      this.highlightedEmoji = emoji.id;
      this.selectedEmoji = emoji.name;
    },
    closeDiaryModals() {
      this.$store.commit("careBlogDiaryModals", false);
    },

    emptyField() {
      this.title = "";
      this.description = "";
      this.newVideo = "";
      this.newVoice = "";
      this.newMedia = "";
      this.highlightedEmoji = 1;
      this.tags = [];
      this.checkedPet = [];
      this.errors = {};
    },

    changeTag(val) {
      val.split(",").map((line) => {
        if (
          val[val.length - 1] == "," &&
          this.tags.findIndex((obj) => obj === line) == -1 &&
          line != ""
        ) {
          this.tags.push(line);
        }
      });
    },
    deleteTag(val) {
      const diags = this.tags.filter((c) => c !== val);
      this.tags = diags;
    },

    closeVideoModals(i) {
      this.addVideoModals = i;
    },
    closeVoiceModals(i) {
      this.addVoiceModals = i;
    },
    closeMediaModals(i) {
      this.addMediaModals = i;
    },
    openAddNewVideoModals(i) {
      this.addNewVideoModals = i;
    },
    openAddNewVoiceModals(i) {
      this.addNewVoiceModals = i;
    },
    openAddNewMediaModals(i) {
      this.addNewMediaModals = i;
    },
    addNewVideo() {
      const input = document.createElement("input");
      input.type = "file";
      input.name = "file[]";
      input.accept = "video/*";

      input.onchange = (e) => {
        //let file = URL.createObjectURL(e.target.files[0]);
        let file = e.target.files[0];
        this.newVideo = file;
        this.newVideoDetails = e.target.files[0].name;
        this.componentKey += 1;
      };

      input.click();
    },
    removeNewVideo() {
      this.newVideo = "";
      this.newVideoDetails = "";
    },
    addNewMedia() {
      const input = document.createElement("input");
      input.type = "file";
      input.name = "file[]";
      input.accept = "image/*";
      input.capture = "camera";

      input.onchange = (e) => {
        // getting a hold of the file reference
        //let file = URL.createObjectURL(e.target.files[0]);
        let file = e.target.files[0]; //e.target.files[0]; //
        this.newMedia = file;
        this.newMediaDetails = e.target.files[0].name;
        this.componentKey += 1;
      };

      input.click();
    },
    removeNewMedia() {
      this.newMedia = "";
      this.newMediaDetails = "";
    },
    addNewVoice() {
      const input = document.createElement("input");
      input.type = "file";
      input.name = "file[]";
      input.accept = "audio/*";

      input.onchange = (e) => {
        // getting a hold of the file reference
        //let file = URL.createObjectURL(e.target.files[0]);
        let file = e.target.files[0];
        this.newVoice = file;
        this.newVoiceDetails = e.target.files[0].name;
        this.componentKey += 1;
      };

      input.click();
    },
    removeNewVoice() {
      this.newVoice = "";
      this.newVoiceDetails = "";
    },

    validate() {
      const errors = {};

      if (this.title.trim() === "")
        errors.title =
          this.$t("global_title") + " " + this.$t("global_is_required");

      if (this.description.trim() === "")
        errors.description =
          this.$t("global_description") + " " + this.$t("global_is_required");

      if (this.date_appointment === "")
        errors.date_appointment =
          this.$t("global_date") + " " + this.$t("global_is_required");

      if (this.time_appointment === "")
        errors.time_appointment =
          this.$t("global_time") + " " + this.$t("global_is_required");

      return Object.keys(errors).length === 0 ? null : errors;
    },

    submitAddCareBlog() {
      const data = {
        pets: this.checkedPet,
        userEmailAddress: localStorage.getItem("email"),
        title: this.title,
        description: this.description,
        mood: this.highlightedEmoji,
        diary_tags: this.tags,
        diary: this.diary,
        video_link: this.newVideo,
        voice_link: this.newVoice,
        image_link: this.newMedia,
      };

      console.log(data);

      const errors = this.validate();
      this.errors = errors || {};
      if (errors) return;

      this.addCareBlog(data);
    },
  },
};
</script>

<style lang="scss">
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  // width: 600px;
  margin: 0px auto;
  padding: 30px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>