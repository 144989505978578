<template>
  <div class="pb-12">
    <div class="flex justify-start items-end">
      <div class="md:w-6/12">
        <img
          class="h-16 ml-28 mt-8 hidden md:block"
          src="@/assets/images/dataservice-logo.svg"
          alt="dataservice-logo"
        />
      </div>
      <div class="md:w-5/12">
        <HeaderGlobal
          titlemenu1="Diary & Routine"
          titlemenu2="Dashboard"
          class="pl-4"
        />
      </div>
    </div>
    <div class="mt-12 flex flex-col justify-center items-center">
      <div class="md:w-8/12 lg:w-10/12 hidden md:block">
        <div class="relative">
          <input
            class="w-full py-3 px-4 rounded-md"
            type="text"
            placeholder="Search"
            v-model="searchWord"
            @keyup.enter="searchMethod"
          />
          <img
            class="h-4 absolute top-4 right-2"
            src="@/assets/images/search-icon.png"
            alt="search-icon"
          />
        </div>
      </div>
      <DashboardYourDashboard />
      <DashboardAttachAppoint />
      <DashboardPets />
      <!-- :petsOwned="petsOwned" -->
      <AddNoteModals :diary="true" :pet="this.$route.params.data" />
    </div>
  </div>
</template>

<script>
import HeaderGlobal from "../../header/HeaderGlobal.vue";
import DashboardYourDashboard from "./DashboardYourDashboard.vue";
import DashboardAttachAppoint from "./DashboardAttachAppoint.vue";
import DashboardPets from "./DashboardPets.vue";
import AddNoteModals from "../../careblog/modals/AddNoteModals.vue";

import DataserviceLogo from "../../DataserviceLogo.vue";

export default {
  components: {
    HeaderGlobal,
    DashboardYourDashboard,
    DashboardAttachAppoint,
    DashboardPets,
    DataserviceLogo,
    AddNoteModals,
  },
  data() {
    return {
      searchWord: "",
    };
  },

  methods: {
    searchMethod() {
      console.log(this.searchWord);
      this.$router.push({ path: "/petownerdiary/allentries" });
    },
  },
};
</script>

<style>
</style>