<template>
  <video class="mx-auto" width="400" controls autoplay>
    <source :src="newVideo" />
    Your browser does not support HTML5 video.
  </video>
</template>

<script>
export default {
    props: ["newVideo"]
};
</script>