<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M26.7979 13.8131L15.3104 25.3006C13.9031 26.7079 11.9944 27.4985 10.0042 27.4985C8.01394 27.4985 6.10523 26.7079 4.69792 25.3006C3.29062 23.8933 2.5 21.9845 2.5 19.9943C2.5 18.0041 3.29062 16.0954 4.69792 14.6881L15.4104 3.97556C16.3486 3.0357 17.6218 2.50703 18.9498 2.50586C20.2777 2.50469 21.5518 3.03111 22.4917 3.96931C23.4315 4.90751 23.9602 6.18065 23.9614 7.50864C23.9625 8.83663 23.4361 10.1107 22.4979 11.0506L11.7604 21.7631C11.2913 22.2322 10.6551 22.4957 9.99167 22.4957C9.32826 22.4957 8.69202 22.2322 8.22292 21.7631C7.75382 21.294 7.49028 20.6577 7.49028 19.9943C7.49028 19.3309 7.75382 18.6947 8.22292 18.2256L18.8354 7.62556"
      :stroke="iconColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 18,
    },
    height: {
      type: [Number, String],
      default: 18,
    },
    iconColor: {
      type: String,
      default: "#fff",
    },
  },
};
</script>