<template>
  <div class="my-6">
    <ViewSection />
    <NavMenuSecondary page="PetownerDiaryView" />
  </div>
</template>

<script>
import ViewSection from "../components/petownerdiary/view/ViewSection.vue";
import NavMenuSecondary from "../components/navigationmenu/secondary/NavMenuSecondary.vue";

export default {
  components: {
    ViewSection,
    NavMenuSecondary,
  },
  data() {
    return {
     
    };
  },
};
</script>