<template>
  <li class="w-5/12 my-2 font-proximaLight flex items-center justify-between cursor-pointer" @click="funcPet">
    <img :src="data.profile_picture" :alt="data.callName" class="h-10 w-10 rounded-full object-cover" />
    <div class="bg-primary py-2 px-4 text-sm text-white rounded-md">
      {{ data.callName }}
    </div>
    <div>
      <div v-if="!checkedPet" class="border-2 border-primary rounded-md bg-white w-5 h-5"></div>
      <img v-else class="w-5 h-5" src="@/assets/images/check-icon.png" alt="check-icon" />
    </div>
  </li>
</template>

<script>
export default {
  props: { data: {} },
  data() {
    return {

      checkedPet: false,
      checkedPetName: "",
    };
  },
  computed: {
    // whichPet() {
    //   if (!this.checkedPet) {
    //     return this.$emit("addPet");
    //   } else {
    //     return this.$emit("removePet");
    //   }
    // },
  },
  created() {

  },
  methods: {
    funcPet() {
      this.checkedPet = !this.checkedPet;
      // this.$emit("addPet");
      if (this.checkedPet) {
        this.$emit("addPet");
      } else {
        this.$emit("removePet");
      }
    },
  },
};
</script>

<style></style>
