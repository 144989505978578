<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.5 10.75C2.5 13.4022 3.55357 15.9457 5.42893 17.8211C7.3043 19.6964 9.84784 20.75 12.5 20.75C15.1522 20.75 17.6957 19.6964 19.5711 17.8211C21.4464 15.9457 22.5 13.4022 22.5 10.75C22.5 8.09784 21.4464 5.5543 19.5711 3.67893C17.6957 1.80357 15.1522 0.75 12.5 0.75C9.84784 0.75 7.3043 1.80357 5.42893 3.67893C3.55357 5.5543 2.5 8.09784 2.5 10.75Z"
      fill="#FFE500"
    />
    <path
      d="M12.5 0.75C10.5222 0.75 8.58879 1.33649 6.9443 2.4353C5.29981 3.53412 4.01809 5.0959 3.26121 6.92317C2.50433 8.75043 2.3063 10.7611 2.69215 12.7009C3.078 14.6407 4.03041 16.4225 5.42894 17.8211C6.82746 19.2196 8.60929 20.172 10.5491 20.5579C12.4889 20.9437 14.4996 20.7457 16.3268 19.9888C18.1541 19.2319 19.7159 17.9502 20.8147 16.3057C21.9135 14.6612 22.5 12.7278 22.5 10.75C22.5 8.09784 21.4464 5.5543 19.5711 3.67893C17.6957 1.80357 15.1522 0.75 12.5 0.75ZM12.5 19.25C10.6953 19.25 8.93102 18.7148 7.43043 17.7122C5.92983 16.7095 4.76025 15.2844 4.0696 13.617C3.37895 11.9496 3.19825 10.1149 3.55034 8.3448C3.90243 6.57472 4.7715 4.9488 6.04766 3.67265C7.32381 2.3965 8.94973 1.52742 10.7198 1.17533C12.4899 0.823244 14.3246 1.00395 15.992 1.6946C17.6594 2.38525 19.0845 3.55482 20.0872 5.05542C21.0898 6.55602 21.625 8.32025 21.625 10.125C21.625 12.5451 20.6636 14.8661 18.9524 16.5773C17.2411 18.2886 14.9201 19.25 12.5 19.25Z"
      fill="#EBCB00"
    />
    <path
      d="M9.5 2.75C9.5 2.94891 9.81607 3.13968 10.3787 3.28033C10.9413 3.42098 11.7044 3.5 12.5 3.5C13.2956 3.5 14.0587 3.42098 14.6213 3.28033C15.1839 3.13968 15.5 2.94891 15.5 2.75C15.5 2.55109 15.1839 2.36032 14.6213 2.21967C14.0587 2.07902 13.2956 2 12.5 2C11.7044 2 10.9413 2.07902 10.3787 2.21967C9.81607 2.36032 9.5 2.55109 9.5 2.75Z"
      fill="#FFF48C"
    />
    <path
      d="M2.5 10.75C2.5 13.4022 3.55357 15.9457 5.42893 17.8211C7.3043 19.6964 9.84784 20.75 12.5 20.75C15.1522 20.75 17.6957 19.6964 19.5711 17.8211C21.4464 15.9457 22.5 13.4022 22.5 10.75C22.5 8.09784 21.4464 5.5543 19.5711 3.67893C17.6957 1.80357 15.1522 0.75 12.5 0.75C9.84784 0.75 7.3043 1.80357 5.42893 3.67893C3.55357 5.5543 2.5 8.09784 2.5 10.75V10.75Z"
      stroke="#45413C"
      stroke-width="0.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      opacity="0.15"
      d="M4.5 22.75C4.5 22.9489 5.34285 23.1397 6.84315 23.2803C8.34344 23.421 10.3783 23.5 12.5 23.5C14.6217 23.5 16.6566 23.421 18.1569 23.2803C19.6571 23.1397 20.5 22.9489 20.5 22.75C20.5 22.5511 19.6571 22.3603 18.1569 22.2197C16.6566 22.079 14.6217 22 12.5 22C10.3783 22 8.34344 22.079 6.84315 22.2197C5.34285 22.3603 4.5 22.5511 4.5 22.75Z"
      fill="#45413C"
    />
    <path
      d="M8 10.75C8 10.8489 7.97068 10.9456 7.91574 11.0278C7.86079 11.11 7.78271 11.1741 7.69134 11.2119C7.59998 11.2498 7.49945 11.2597 7.40246 11.2404C7.30546 11.2211 7.21637 11.1735 7.14645 11.1036C7.07652 11.0336 7.0289 10.9445 7.00961 10.8475C6.99031 10.7506 7.00022 10.65 7.03806 10.5587C7.0759 10.4673 7.13999 10.3892 7.22221 10.3343C7.30444 10.2793 7.40111 10.25 7.5 10.25C7.63261 10.25 7.75979 10.3027 7.85355 10.3964C7.94732 10.4902 8 10.6174 8 10.75Z"
      fill="#45413C"
      stroke="#45413C"
      stroke-width="0.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5.25 13.25C5.25 13.665 5.81 14 6.5 14C7.19 14 7.75 13.665 7.75 13.25C7.75 12.835 7.19 12.5 6.5 12.5C5.81 12.5 5.25 12.835 5.25 13.25Z"
      fill="#FFAA54"
    />
    <path
      d="M17 10.75C17 10.8489 17.0293 10.9456 17.0843 11.0278C17.1392 11.11 17.2173 11.1741 17.3087 11.2119C17.4 11.2498 17.5006 11.2597 17.5975 11.2404C17.6945 11.2211 17.7836 11.1735 17.8536 11.1036C17.9235 11.0336 17.9711 10.9445 17.9904 10.8475C18.0097 10.7506 17.9998 10.65 17.9619 10.5587C17.9241 10.4673 17.86 10.3892 17.7778 10.3343C17.6956 10.2793 17.5989 10.25 17.5 10.25C17.3674 10.25 17.2402 10.3027 17.1464 10.3964C17.0527 10.4902 17 10.6174 17 10.75Z"
      fill="#45413C"
      stroke="#45413C"
      stroke-width="0.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M19.75 13.25C19.75 13.665 19.19 14 18.5 14C17.81 14 17.25 13.665 17.25 13.25C17.25 12.835 17.81 12.5 18.5 12.5C19.19 12.5 19.75 12.835 19.75 13.25Z"
      fill="#FFAA54"
    />
    <path
      d="M15.3106 15.3357C14.0006 14.8857 11.9806 15.3357 10.3906 16.5057"
      stroke="#45413C"
      stroke-width="0.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 26,
    },
    height: {
      type: [Number, String],
      default: 26,
    },
    iconColor: {
      type: String,
      default: "#c4c4c4",
    },
  },
};
</script>