<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.95312 10.75C2.95313 13.4022 4.00669 15.9457 5.88206 17.8211C7.75742 19.6964 10.301 20.75 12.9531 20.75C15.6053 20.75 18.1488 19.6964 20.0242 17.8211C21.8996 15.9457 22.9531 13.4022 22.9531 10.75C22.9531 8.09784 21.8996 5.5543 20.0242 3.67893C18.1488 1.80357 15.6053 0.75 12.9531 0.75C10.301 0.75 7.75742 1.80357 5.88206 3.67893C4.00669 5.5543 2.95313 8.09784 2.95312 10.75Z"
      fill="#FFE500"
    />
    <path
      d="M12.9531 0.75C10.9753 0.75 9.04192 1.33649 7.39743 2.4353C5.75294 3.53412 4.47121 5.0959 3.71433 6.92317C2.95746 8.75043 2.75942 10.7611 3.14528 12.7009C3.53113 14.6407 4.48354 16.4225 5.88206 17.8211C7.28059 19.2196 9.06242 20.172 11.0022 20.5579C12.942 20.9437 14.9527 20.7457 16.78 19.9888C18.6072 19.2319 20.169 17.9502 21.2678 16.3057C22.3666 14.6612 22.9531 12.7278 22.9531 10.75C22.9531 8.09784 21.8996 5.5543 20.0242 3.67893C18.1488 1.80357 15.6053 0.75 12.9531 0.75ZM12.9531 19.25C11.1484 19.25 9.38415 18.7148 7.88355 17.7122C6.38295 16.7095 5.21338 15.2844 4.52273 13.617C3.83208 11.9496 3.65137 10.1149 4.00346 8.3448C4.35555 6.57472 5.22463 4.9488 6.50078 3.67265C7.77693 2.3965 9.40285 1.52742 11.1729 1.17533C12.943 0.823244 14.7777 1.00395 16.4451 1.6946C18.1125 2.38525 19.5376 3.55482 20.5403 5.05542C21.543 6.55602 22.0781 8.32025 22.0781 10.125C22.0781 12.5451 21.1167 14.8661 19.4055 16.5773C17.6942 18.2886 15.3732 19.25 12.9531 19.25Z"
      fill="#EBCB00"
    />
    <path
      d="M9.95312 2.75C9.95312 2.94891 10.2692 3.13968 10.8318 3.28033C11.3944 3.42098 12.1575 3.5 12.9531 3.5C13.7488 3.5 14.5118 3.42098 15.0744 3.28033C15.6371 3.13968 15.9531 2.94891 15.9531 2.75C15.9531 2.55109 15.6371 2.36032 15.0744 2.21967C14.5118 2.07902 13.7488 2 12.9531 2C12.1575 2 11.3944 2.07902 10.8318 2.21967C10.2692 2.36032 9.95312 2.55109 9.95312 2.75Z"
      fill="#FFF48C"
    />
    <path
      d="M2.95312 10.75C2.95313 13.4022 4.00669 15.9457 5.88206 17.8211C7.75742 19.6964 10.301 20.75 12.9531 20.75C15.6053 20.75 18.1488 19.6964 20.0242 17.8211C21.8996 15.9457 22.9531 13.4022 22.9531 10.75C22.9531 8.09784 21.8996 5.5543 20.0242 3.67893C18.1488 1.80357 15.6053 0.75 12.9531 0.75C10.301 0.75 7.75742 1.80357 5.88206 3.67893C4.00669 5.5543 2.95313 8.09784 2.95312 10.75V10.75Z"
      stroke="#45413C"
      stroke-width="0.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M6.25 11.6133C6.25 11.8122 6.3817 12.003 6.61612 12.1436C6.85054 12.2843 7.16848 12.3633 7.5 12.3633C7.83152 12.3633 8.14946 12.2843 8.38388 12.1436C8.6183 12.003 8.75 11.8122 8.75 11.6133C8.75 11.4144 8.6183 11.2236 8.38388 11.083C8.14946 10.9423 7.83152 10.8633 7.5 10.8633C7.16848 10.8633 6.85054 10.9423 6.61612 11.083C6.3817 11.2236 6.25 11.4144 6.25 11.6133Z"
      fill="#FFAA54"
    />
    <path
      d="M17.1562 11.6133C17.1562 11.8122 17.2879 12.003 17.5224 12.1436C17.7568 12.2843 18.0747 12.3633 18.4062 12.3633C18.7378 12.3633 19.0557 12.2843 19.2901 12.1436C19.5246 12.003 19.6562 11.8122 19.6562 11.6133C19.6562 11.4144 19.5246 11.2236 19.2901 11.083C19.0557 10.9423 18.7378 10.8633 18.4062 10.8633C18.0747 10.8633 17.7568 10.9423 17.5224 11.083C17.2879 11.2236 17.1562 11.4144 17.1562 11.6133Z"
      fill="#FFAA54"
    />
    <path
      d="M8.81953 14C8.74853 14.0001 8.67838 14.0153 8.61372 14.0446C8.54906 14.0739 8.49138 14.1167 8.44453 14.17C8.39734 14.2225 8.36181 14.2844 8.34027 14.3516C8.31872 14.4188 8.31165 14.4899 8.31953 14.56C8.4588 15.6912 9.00694 16.7324 9.86068 17.4875C10.7144 18.2425 11.8148 18.6593 12.9545 18.6593C14.0943 18.6593 15.1946 18.2425 16.0484 17.4875C16.9021 16.7324 17.4503 15.6912 17.5895 14.56C17.5974 14.4899 17.5903 14.4188 17.5688 14.3516C17.5472 14.2844 17.5117 14.2225 17.4645 14.17C17.4177 14.1167 17.36 14.0739 17.2953 14.0446C17.2307 14.0153 17.1605 14.0001 17.0895 14H8.81953Z"
      fill="#FFB0CA"
      stroke="#45413C"
      stroke-width="0.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M16.6334 16.8632C15.5008 16.2654 14.2338 15.9676 12.9534 15.9982C11.673 15.9676 10.4061 16.2654 9.27344 16.8632C9.70907 17.424 10.2671 17.8778 10.9049 18.1899C11.5427 18.5021 12.2434 18.6644 12.9534 18.6644C13.6635 18.6644 14.3642 18.5021 15.002 18.1899C15.6398 17.8778 16.1978 17.424 16.6334 16.8632Z"
      fill="#FF87AF"
      stroke="#45413C"
      stroke-width="0.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M16.7123 16.1145C16.7345 16.0725 16.77 16.039 16.8133 16.0193C16.8566 15.9996 16.9051 15.9948 16.9514 16.0057C16.9977 16.0165 17.0391 16.0424 17.0691 16.0793C17.0991 16.1162 17.116 16.162 17.1173 16.2095V20.8045C17.1012 21.009 17.0086 21.1999 16.858 21.3391C16.7074 21.4784 16.5099 21.5557 16.3048 21.5557C16.0996 21.5557 15.9021 21.4784 15.7515 21.3391C15.6009 21.1999 15.5083 21.009 15.4923 20.8045V18.3045C15.4881 17.8339 15.6675 17.3801 15.9923 17.0395C16.2768 16.7686 16.5194 16.4568 16.7123 16.1145Z"
      fill="#4AEFF7"
      stroke="#45413C"
      stroke-width="0.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      opacity="0.15"
      d="M4.95312 22.75C4.95313 22.9489 5.79598 23.1397 7.29627 23.2803C8.79656 23.421 10.8314 23.5 12.9531 23.5C15.0749 23.5 17.1097 23.421 18.61 23.2803C20.1103 23.1397 20.9531 22.9489 20.9531 22.75C20.9531 22.5511 20.1103 22.3603 18.61 22.2197C17.1097 22.079 15.0749 22 12.9531 22C10.8314 22 8.79656 22.079 7.29627 22.2197C5.79598 22.3603 4.95313 22.5511 4.95312 22.75Z"
      fill="#45413C"
    />
    <path
      d="M19.7919 7.90923C19.6725 8.18026 19.4992 8.42417 19.2826 8.62616C19.066 8.82815 18.8106 8.984 18.5319 9.08423L15.5319 10.0842L14.2869 7.16423C14.1754 6.88905 14.1219 6.59384 14.1296 6.29704C14.1374 6.00025 14.2062 5.70823 14.3319 5.43923C14.4366 5.20889 14.5915 5.00491 14.7853 4.84225C14.9791 4.67959 15.2069 4.56239 15.4519 4.49923C15.7856 4.42199 16.1362 4.47597 16.4312 4.65001C16.7262 4.82404 16.9431 5.10481 17.0369 5.43423L17.2719 6.18923L17.9869 5.86423C18.2946 5.71451 18.6482 5.68916 18.9741 5.79346C19.3001 5.89776 19.5732 6.12368 19.7369 6.42423C19.859 6.65108 19.9274 6.90291 19.9369 7.16034C19.9465 7.41778 19.8968 7.67397 19.7919 7.90923Z"
      fill="#FF6242"
    />
    <path
      d="M14.8522 5.86547C15.188 5.78518 15.5418 5.8376 15.8399 6.01179C16.138 6.18599 16.3573 6.46851 16.4522 6.80047L16.6822 7.55547L17.4022 7.23047C17.7101 7.082 18.0633 7.05732 18.3889 7.1615C18.7144 7.26569 18.9877 7.49081 19.1522 7.79047C19.2807 8.03172 19.3445 8.3022 19.3372 8.57547C19.5329 8.38416 19.6922 8.15882 19.8072 7.91047C19.9176 7.67473 19.9719 7.41661 19.9659 7.15636C19.9598 6.8961 19.8935 6.6408 19.7722 6.41047C19.6086 6.10991 19.3354 5.88399 19.0094 5.77969C18.6835 5.67539 18.3299 5.70074 18.0222 5.85047L17.3072 6.17547L17.0722 5.42047C16.9753 5.08623 16.7518 4.80302 16.4492 4.63117C16.1466 4.45932 15.7889 4.41243 15.4522 4.50047C15.1967 4.5665 14.9601 4.69129 14.7613 4.8649C14.5625 5.03851 14.407 5.25615 14.3072 5.50047C14.1959 5.75341 14.1315 6.02449 14.1172 6.30047C14.3171 6.09012 14.5716 5.93952 14.8522 5.86547Z"
      fill="#FF866E"
    />
    <path
      d="M19.7919 7.90923C19.6725 8.18026 19.4992 8.42417 19.2826 8.62616C19.066 8.82815 18.8106 8.984 18.5319 9.08423L15.5319 10.0842L14.2869 7.16423C14.1754 6.88905 14.1219 6.59384 14.1296 6.29704C14.1374 6.00025 14.2062 5.70823 14.3319 5.43923C14.4366 5.20889 14.5915 5.00491 14.7853 4.84225C14.9791 4.67959 15.2069 4.56239 15.4519 4.49923C15.7856 4.42199 16.1362 4.47597 16.4312 4.65001C16.7262 4.82404 16.9431 5.10481 17.0369 5.43423L17.2719 6.18923L17.9869 5.86423C18.2946 5.71451 18.6482 5.68916 18.9741 5.79346C19.3001 5.89776 19.5732 6.12368 19.7369 6.42423C19.859 6.65108 19.9274 6.90291 19.9369 7.16034C19.9465 7.41778 19.8968 7.67397 19.7919 7.90923V7.90923Z"
      stroke="#45413C"
      stroke-width="0.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M4.10176 4.0015C4.38828 3.56128 4.83355 3.24854 5.34488 3.12839C5.8562 3.00825 6.39417 3.08996 6.84676 3.3565L7.90176 3.9515L8.35176 2.8315C8.53501 2.3398 8.90069 1.93761 9.37279 1.70855C9.8449 1.47948 10.3871 1.44114 10.8868 1.6015C11.267 1.7279 11.6126 1.94095 11.8963 2.22384C12.1801 2.50672 12.3942 2.85168 12.5218 3.2315C12.6781 3.65748 12.7459 4.11088 12.721 4.56396C12.696 5.01704 12.5789 5.46026 12.3768 5.8665L10.1168 10.1765L5.64176 8.26151C5.22872 8.07444 4.85824 7.80499 4.55302 7.46968C4.2478 7.13437 4.01427 6.74026 3.86676 6.3115C3.7219 5.93504 3.66768 5.52976 3.70851 5.12845C3.74933 4.72715 3.88405 4.34109 4.10176 4.0015Z"
      fill="#FF6242"
    />
    <path
      d="M4.88827 6.2115C5.17479 5.77128 5.62006 5.45854 6.13139 5.33839C6.64271 5.21825 7.18067 5.29996 7.63327 5.5665L8.68827 6.1515L9.13827 5.03151C9.32055 4.53888 9.68582 4.13555 10.158 3.9055C10.6302 3.67545 11.173 3.63641 11.6733 3.7965C12.0717 3.92679 12.4299 4.15754 12.7133 4.46651C12.7245 4.04677 12.6602 3.62844 12.5233 3.2315C12.3957 2.85168 12.1816 2.50672 11.8978 2.22384C11.6141 1.94095 11.2685 1.7279 10.8883 1.6015C10.3886 1.44114 9.84641 1.47948 9.3743 1.70855C8.90219 1.93761 8.53652 2.3398 8.35327 2.8315L7.90327 3.9515L6.84827 3.3665C6.39686 3.09902 5.85974 3.01575 5.34854 3.13401C4.83733 3.25227 4.39134 3.56295 4.10327 4.0015C3.88716 4.33988 3.75334 4.72417 3.71253 5.12359C3.67172 5.523 3.72506 5.92642 3.86827 6.30151C4.0109 6.7024 4.22616 7.07359 4.50327 7.3965C4.52165 6.97388 4.65474 6.56423 4.88827 6.2115Z"
      fill="#FF866E"
    />
    <path
      d="M4.10176 4.0015C4.38828 3.56128 4.83355 3.24854 5.34488 3.12839C5.8562 3.00825 6.39417 3.08996 6.84676 3.3565L7.90176 3.9515L8.35176 2.8315C8.53501 2.3398 8.90069 1.93761 9.37279 1.70855C9.8449 1.47948 10.3871 1.44114 10.8868 1.6015C11.267 1.7279 11.6126 1.94095 11.8963 2.22384C12.1801 2.50672 12.3942 2.85168 12.5218 3.2315C12.6781 3.65748 12.7459 4.11088 12.721 4.56396C12.696 5.01704 12.5789 5.46026 12.3768 5.8665L10.1168 10.1765L5.64176 8.26151C5.22872 8.07444 4.85824 7.80499 4.55302 7.46968C4.2478 7.13437 4.01427 6.74026 3.86676 6.3115C3.7219 5.93504 3.66768 5.52976 3.70851 5.12845C3.74933 4.72715 3.88405 4.34109 4.10176 4.0015V4.0015Z"
      stroke="#45413C"
      stroke-width="0.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 26,
    },
    height: {
      type: [Number, String],
      default: 26,
    },
    iconColor: {
      type: String,
      default: "#c4c4c4",
    },
  },
};
</script>