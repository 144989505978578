<template>
  <Transition name="modal">
    <div v-if="compAddAppointmentModals" class="modal-mask">
      <div class="modal-wrapper">
        <div
          class="modal-container md:w-10/12 lg:w-6/12 2xl:w-5/12 flex flex-col justify-center items-center font-proximaMedium overflow-y-auto"
        >
          <div class="">
            <div class="w-max m-auto p-4 bg-primary-20 rounded-full">
              <AppointmentIcon width="90" height="90" />
            </div>
            <h1 class="mt-4 text-2xl">
              {{ $t("modals_add_appointment_title") }}
            </h1>
            <FormError :errors="errors.save" />
          </div>
          <div class="w-full mt-4">
            <h5 class="text-left">{{ $t("global_description") }}</h5>
            <input
              class="w-full p-2 border-2 border-primary rounded-md"
              type="text"
              v-model="description"
            />
            <FormError :errors="errors.description" />
          </div>
          <div class="w-full mt-4 flex justify-between">
            <div class="w-6/12 mr-1">
              <h5 class="text-left">{{ $t("global_date") }}</h5>
              <date-picker
                type="date"
                format="DD/MM/YYYY"
                value-type="format"
                v-model="date_appointment"
                default-value="date_appointment"
                @change="onDateChange()"
                class="w-full rounded-md p-1 border-2 border-primary my-1 text-sm"
              ></date-picker>

              <FormError :errors="errors.date_appointment" />
            </div>
            <div class="w-6/12 ml-1">
              <h5 class="text-left">{{ $t("global_time") }}</h5>
              <date-picker
                format="hh:mm"
                type="time"
                value-type="format"
                v-model="time_appointment"
                default-value="time_appointment"
                @change="onTimeChange()"
                class="w-full rounded-md p-1 border-2 border-primary my-1 text-sm"
              ></date-picker>
              <FormError :errors="errors.time_appointment" />
            </div>
          </div>
          <div class="w-full mt-4">
            <h5 class="text-left">{{ $t("global_location") }}</h5>
            <input
              class="w-full p-2 border-2 border-primary rounded-md"
              type="text"
              v-model="location"
              @keyup.enter="onChangeLocation"
            />
            <!-- <FormError :errors="errors.save" /> -->
            <!-- <AddGoogleMap /> -->

            <!--  <MapLocation :lat="getMapLocationLat" :lon="getMapLocationLon" /> -->
          </div>
          <!--  <div class="mt-4 flex items-center justify-between">
            <span class="font-proximaLight text-sm">{{
              $t("modals_add_appointment_sync_calendar")
            }}</span>
            <RadioOnoffBorder
              :checked="syncCal"
              :val="syncCalVal"
              @inputVal="radioVal"
            />
          </div> -->
          <div class="mt-12 w-full md:w-8/12 flex justify-evenly">
            <div class="mx-auto w-10/12 md:w-5/12 relative">
              <BorderedButton
                class="mx-auto"
                marginT="mt-2"
                :title="$t('global_cancel')"
                paddingX="px-6"
                width="w-full"
                @click.native="closeAppointmentModals"
              />
            </div>
            <div class="mx-auto w-10/12 md:w-5/12 relative">
              <SubmitButton
                class="mx-auto"
                marginT="mt-2"
                :title="$t('global_confirm')"
                background="bg-success"
                paddingX="px-6"
                width="w-full"
                @click.native="submitAddAppointment"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
import SubmitButton from "../../buttons/SubmitButton.vue";
import BorderedButton from "../../buttons/BorderedButton.vue";
import PetOwnedList from "./PetOwnedList.vue";
//import MapLocation from "./MapLocation.vue";

import RadioOnoffBorder from "@/components/forms/RadioOnoffBorder.vue";
import TrashIcon from "@/components/icons/TrashIcon.vue";
import AppointmentIcon from "../../icons/AppointmentIcon.vue";
import DatePicker from "vue2-datepicker";
import FormError from "../../warning/FormError.vue";
import "vue2-datepicker/index.css";
import { getCoordinatesFromAddress } from "../../../services/careBlogService";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    SubmitButton,
    //MapLocation,
    BorderedButton,
    PetOwnedList,
    RadioOnoffBorder,
    TrashIcon,
    FormError,
    AppointmentIcon,
    DatePicker,
  },
  data() {
    return {
      pet: {},
      togglePets: false,
      checkedPet: [],
      highlightedEmoji: "",
      selectedEmoji: "",
      description: "",
      date_appointment: "",
      time_appointment: "",
      location: "",
      syncCal: false,
      syncCalVal: {},
      errors: {},
      lon: -109.83,
      lat: 45.03,
    };
  },
  computed: {
    compAddAppointmentModals() {
      return this.$store.state.careBlogAppointmentModalsState;
    },
    /* getMapLocationLat() {
      return this.$store.state.mapLocationLatState;
    },
    getMapLocationLon() {
      return this.$store.state.mapLocationLonState;
    }, */
  },

  created() {
    var retrievedObject = localStorage.getItem("pet");
    this.pet = JSON.parse(retrievedObject);
  },

  methods: {
    ...mapActions({
      addAppointment: "appointment/addAppointment",
    }),

    closeAppointmentModals() {
      this.$store.commit("careBlogAppointmentModals", false);
      this.errors = {};
    },
    onDateChange() {
      this.date_appointment = this.date_appointment;
      console.log(this.date_appointment);
    },
    onTimeChange() {
      this.time_appointment = this.time_appointment;
      console.log(this.time_appointment);
    },

    async onChangeLocation() {
      try {
        const { data: response } = await getCoordinatesFromAddress(
          this.location
        );

        const lat = response.latitude;
        const lon = response.longitude;

        this.$store.commit("mapLocationLat", lat);
        this.$store.commit("mapLocationLon", lon);
        this.lat = lat;
        this.lon = lon;
        console.log(response.latitude, response.longitude);
      } catch (error) {
        console.log(error);
      }
    },
    radioVal() {
      this.syncCal = !this.syncCal;
      console.log(this.syncCal);
    },
    validate() {
      const errors = {};

      if (this.description.trim() === "")
        errors.description =
          this.$t("global_description") + " " + this.$t("global_is_required");

      if (this.date_appointment === "")
        errors.date_appointment =
          this.$t("global_date") + " " + this.$t("global_is_required");

      if (this.time_appointment === "")
        errors.time_appointment =
          this.$t("global_time") + " " + this.$t("global_is_required");

      return Object.keys(errors).length === 0 ? null : errors;
    },
    submitAddAppointment() {
      try {
        const data = {
          description: this.description,
          date_appointment: this.date_appointment,
          time_appointment: this.time_appointment,
          userEmailAddress: localStorage.getItem("email"),
          petID: this.pet.petID,
          location_appointment_long: this.lon,
          location_appointment_lat: this.lat,
        };

        console.log(data);

        const errors = this.validate();
        this.errors = errors || {};
        if (errors) return;

        this.addAppointment(data);
        this.closeAppointmentModals();
      } catch (error) {
        console.log(error);
        this.errors.save = "Save error.";
        console.log("Save error.");
      }
    },
  },
};
</script>

<style lang="scss">
.mx-datepicker {
  width: 100%;
}

.mx-datepicker-popup {
  z-index: 9999;
}

.mx-input {
  border: none;
  box-shadow: none;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  // width: 600px;
  margin: 0px auto;
  padding: 30px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
