<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.95312 10.75C2.95313 13.4022 4.00669 15.9457 5.88206 17.8211C7.75742 19.6964 10.301 20.75 12.9531 20.75C15.6053 20.75 18.1488 19.6964 20.0242 17.8211C21.8996 15.9457 22.9531 13.4022 22.9531 10.75C22.9531 8.09784 21.8996 5.5543 20.0242 3.67893C18.1488 1.80357 15.6053 0.75 12.9531 0.75C10.301 0.75 7.75742 1.80357 5.88206 3.67893C4.00669 5.5543 2.95313 8.09784 2.95312 10.75Z"
      fill="#FFE500"
    />
    <path
      d="M12.9531 0.75C10.9753 0.75 9.04192 1.33649 7.39743 2.4353C5.75294 3.53412 4.47121 5.0959 3.71433 6.92317C2.95746 8.75043 2.75942 10.7611 3.14528 12.7009C3.53113 14.6407 4.48354 16.4225 5.88206 17.8211C7.28059 19.2196 9.06242 20.172 11.0022 20.5579C12.942 20.9437 14.9527 20.7457 16.78 19.9888C18.6072 19.2319 20.169 17.9502 21.2678 16.3057C22.3666 14.6612 22.9531 12.7278 22.9531 10.75C22.9531 8.09784 21.8996 5.5543 20.0242 3.67893C18.1488 1.80357 15.6053 0.75 12.9531 0.75ZM12.9531 19.25C11.1484 19.25 9.38415 18.7148 7.88355 17.7122C6.38295 16.7095 5.21338 15.2844 4.52273 13.617C3.83208 11.9496 3.65137 10.1149 4.00346 8.3448C4.35555 6.57472 5.22463 4.9488 6.50078 3.67265C7.77693 2.3965 9.40285 1.52742 11.1729 1.17533C12.943 0.823244 14.7777 1.00395 16.4451 1.6946C18.1125 2.38525 19.5376 3.55482 20.5403 5.05542C21.543 6.55602 22.0781 8.32025 22.0781 10.125C22.0781 12.5451 21.1167 14.8661 19.4055 16.5773C17.6942 18.2886 15.3732 19.25 12.9531 19.25Z"
      fill="#EBCB00"
    />
    <path
      d="M9.95312 2.75C9.95312 2.94891 10.2692 3.13968 10.8318 3.28033C11.3944 3.42098 12.1575 3.5 12.9531 3.5C13.7488 3.5 14.5118 3.42098 15.0744 3.28033C15.6371 3.13968 15.9531 2.94891 15.9531 2.75C15.9531 2.55109 15.6371 2.36032 15.0744 2.21967C14.5118 2.07902 13.7488 2 12.9531 2C12.1575 2 11.3944 2.07902 10.8318 2.21967C10.2692 2.36032 9.95312 2.55109 9.95312 2.75Z"
      fill="#FFF48C"
    />
    <path
      d="M2.95312 10.75C2.95313 13.4022 4.00669 15.9457 5.88206 17.8211C7.75742 19.6964 10.301 20.75 12.9531 20.75C15.6053 20.75 18.1488 19.6964 20.0242 17.8211C21.8996 15.9457 22.9531 13.4022 22.9531 10.75C22.9531 8.09784 21.8996 5.5543 20.0242 3.67893C18.1488 1.80357 15.6053 0.75 12.9531 0.75C10.301 0.75 7.75742 1.80357 5.88206 3.67893C4.00669 5.5543 2.95313 8.09784 2.95312 10.75V10.75Z"
      stroke="#45413C"
      stroke-width="0.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.4531 15.7505C11.1931 15.2561 12.0631 14.9922 12.9531 14.9922C13.8431 14.9922 14.7131 15.2561 15.4531 15.7505"
      stroke="#45413C"
      stroke-width="0.5"
      stroke-linecap="round"
    />
    <path
      opacity="0.15"
      d="M4.95312 22.75C4.95313 22.9489 5.79598 23.1397 7.29627 23.2803C8.79656 23.421 10.8314 23.5 12.9531 23.5C15.0749 23.5 17.1097 23.421 18.61 23.2803C20.1103 23.1397 20.9531 22.9489 20.9531 22.75C20.9531 22.5511 20.1103 22.3603 18.61 22.2197C17.1097 22.079 15.0749 22 12.9531 22C10.8314 22 8.79656 22.079 7.29627 22.2197C5.79598 22.3603 4.95313 22.5511 4.95312 22.75Z"
      fill="#45413C"
    />
    <path
      d="M8.45313 10.75C8.45313 10.8489 8.4238 10.9456 8.36886 11.0278C8.31392 11.11 8.23583 11.1741 8.14447 11.2119C8.0531 11.2498 7.95257 11.2597 7.85558 11.2404C7.75859 11.2211 7.6695 11.1735 7.59957 11.1036C7.52965 11.0336 7.48203 10.9445 7.46273 10.8475C7.44344 10.7506 7.45334 10.65 7.49119 10.5587C7.52903 10.4673 7.59312 10.3892 7.67534 10.3343C7.75756 10.2793 7.85423 10.25 7.95313 10.25C8.08573 10.25 8.21291 10.3027 8.30668 10.3964C8.40045 10.4902 8.45313 10.6174 8.45313 10.75Z"
      fill="#45413C"
      stroke="#45413C"
      stroke-width="0.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.45312 8.58C8.03998 8.48541 8.60848 8.29985 9.13813 8.03C9.66979 7.76534 10.1559 7.41765 10.5781 7"
      stroke="#45413C"
      stroke-width="0.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M17.4531 10.75C17.4531 10.8489 17.4824 10.9456 17.5374 11.0278C17.5923 11.11 17.6704 11.1741 17.7618 11.2119C17.8531 11.2498 17.9537 11.2597 18.0507 11.2404C18.1477 11.2211 18.2368 11.1735 18.3067 11.1036C18.3766 11.0336 18.4242 10.9445 18.4435 10.8475C18.4628 10.7506 18.4529 10.65 18.4151 10.5587C18.3772 10.4673 18.3131 10.3892 18.2309 10.3343C18.1487 10.2793 18.052 10.25 17.9531 10.25C17.8205 10.25 17.6933 10.3027 17.5996 10.3964C17.5058 10.4902 17.4531 10.6174 17.4531 10.75Z"
      fill="#45413C"
      stroke="#45413C"
      stroke-width="0.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M18.4531 8.58C17.8663 8.48541 17.2978 8.29985 16.7681 8.03C16.2365 7.76534 15.7504 7.41765 15.3281 7"
      stroke="#45413C"
      stroke-width="0.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M20.2031 13.25C20.2031 13.665 19.6431 14 18.9531 14C18.2631 14 17.7031 13.665 17.7031 13.25C17.7031 12.835 18.2631 12.5 18.9531 12.5C19.6431 12.5 20.2031 12.835 20.2031 13.25Z"
      fill="#FFAA54"
    />
    <path
      d="M5.70312 13.25C5.70312 13.665 6.26313 14 6.95312 14C7.64313 14 8.20312 13.665 8.20312 13.25C8.20312 12.835 7.64313 12.5 6.95312 12.5C6.26313 12.5 5.70312 12.835 5.70312 13.25Z"
      fill="#FFAA54"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 26,
    },
    height: {
      type: [Number, String],
      default: 26,
    },
    iconColor: {
      type: String,
      default: "#c4c4c4",
    },
  },
};
</script>