<template>
  <HeartIcon
    class="absolute top-4 right-4 cursor-pointer"
    @click.native="clickFavorite"
    :iconColor="favoriteColor"
  />
</template>

<script>
import HeartIcon from "../../icons/HeartIcon.vue";

export default {
  props:["f"],
  components: {
    HeartIcon,
  },
  data() {
    return {
      favoriteToggle: this.f,
    };
  },
  computed: {
    favoriteColor() {
      console.log(this.f);
      if (this.favoriteToggle) {
        return "#ff0c00";
      } else {
        return "#354E57";
      }
    },
  },
  methods: {
    clickFavorite() {
      this.favoriteToggle = !this.favoriteToggle;
    },
  },
};
</script>

<style>
</style>