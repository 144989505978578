<template>
  <div class="pb-12">
    <div class="flex justify-start items-center">
      <div class="md:w-6/12 flex justify-start">
        <img
          class="w-max h-32 ml-28 mt-0 hidden md:block"
          src="@/assets/images/dataservice-logo.svg"
          alt="dataservice-logo"
        />
      </div>
      <div class="md:w-5/12">
        <HeaderGlobal
          titlemenu1="Diary"
          titlemenu2="All Entries"
          class="pl-4"
        />
      </div>
    </div>
    <div class="mt-12 flex flex-col justify-center items-center">
      <div class="w-11/12 md:w-8/12 lg:w-8/12">
        <div class="relative">
          <input
            class="w-full py-3 px-4 rounded-md"
            type="text"
            placeholder="Search"
            v-model="searchWord"
            @keyup.enter="searchMethod"
          />
          <img
            class="h-4 absolute top-4 right-2"
            src="@/assets/images/search-icon.png"
            alt="search-icon"
          />
        </div>
      </div>
      <div
        class="mt-6 w-11/12 md:w-8/12 lg:w-8/12 text-left font-proximaMedium text-subheaderText"
      >
        <p>
          Hi Scarlet, <br />
          Welcome!
        </p>
        <span class="font-proximaLight text-lightGrey text-sm">{{
          dateDay
        }}</span>
        <h5>
          {{ pet.callName }} thinks you're about to do something great today.
        </h5>
      </div>
      <div
        class="mt-6 md:w-8/12 lg:w-8/12 bg-white rounded-xl font-proximaMedium text-subheaderText"
      >
        <div class="w-8/12 py-4 mx-auto flex items-center justify-between">
          <span
            class="pb-1 text-lg cursor-pointer"
            :class="[whichTab == 'all' ? ['border-b-2', 'border-primary'] : '']"
            @click="tabs('all')"
            >All Entries</span
          >
          <span
            class="pb-1 text-lg cursor-pointer"
            :class="[
              whichTab == 'favorite' ? ['border-b-2', 'border-primary'] : '',
            ]"
            @click="tabs('favorite')"
            >Favourites</span
          >
        </div>
        <div>
          <ViewAllentries :careBlogs="careBlogss" v-if="whichTab == 'all'" />
          <ViewFavorite v-else />
        </div>
      </div>
    </div>
    <!-- :petsOwned="petsOwned" -->
    <AddNoteModals :diary="true" :pet="this.$route.params.data" />
    <AddAppointmentModals :pet="this.$route.params.data" />
  </div>
</template>

<script>
import moment from "moment";
import HeaderGlobal from "../../header/HeaderGlobal.vue";
import ViewAllentries from "./ViewAllentries.vue";
import ViewFavorite from "./ViewFavorite.vue";
import AddNoteModals from "../../careblog/modals/AddNoteModals.vue";
import AddAppointmentModals from "../../careblog/modals/AddAppointmentModals.vue";

import { mapActions, mapGetters } from "vuex";

import DataserviceLogo from "../../DataserviceLogo.vue";

export default {
  components: {
    HeaderGlobal,
    ViewAllentries,
    ViewFavorite,
    DataserviceLogo,
    AddNoteModals,
    AddAppointmentModals
  },
  data() {
    return {
      whichTab: "all",
      searchWord: "",
      pet: "",
    };
  },
  computed: {
    ...mapGetters({
      careBlogs: "appointment/getCareBlogs",
    }),
    careBlogss() {
      console.log(this.careBlogs);
      return localStorage.getItem("role") == 1
        ? this.careBlogs.diary_entries
        : this.careBlogs.care_blog_entries;
    },
    dateDay() {
      var d = new Date();
      return moment(String(d)).format("dddd DD MMM YYYY");
    },
  },
  created() {
    // Retrieve the pet object from storage
    var retrievedObject = localStorage.getItem("pet");

    this.pet = JSON.parse(retrievedObject);

    this.getCareBlogs(this.pet.petID);
  },
  methods: {
    ...mapActions({
      getCareBlogs: "appointment/getCareBlogs",
    }),
    tabs(i) {
      this.whichTab = i;
    },
    searchMethod() {
      console.log(this.searchWord);
    },
  },
};
</script>

<style>
</style>