<template>
  <div class="pb-12">
    <div class="flex justify-start items-center">
      <div class="md:w-6/12 flex justify-start">
        <img
          class="w-max h-32 ml-28 mt-0 hidden md:block"
          src="@/assets/images/dataservice-logo.svg"
          alt="dataservice-logo"
        />
      </div>
      <div class="md:w-5/12">
        <HeaderGlobal titlemenu1="Diary Entries" />
      </div>
    </div>
    <div class="mt-12 flex flex-col justify-center items-center">
      <div class="
          mt-6
          md:w-8/12
          lg:w-8/12
          bg-white
          rounded-xl
          font-proximaMedium
          text-subheaderText
        ">
        <div>
          <ul>
            <li class="m-6 bg-whiteBlue p-4 lg:pr-20 text-left relative" v-for="(data, i) in careBlogs" :key="i">
              <PetImagesComponent :images="data.pets_profiles" class="hidden lg:flex" />
              <EditIcon class="absolute bottom-4 right-4 cursor-pointer" iconColor="#0AC0CB"
                @click.native="openEditModals(data)" />
              <h5 class="font-proximaMedium">{{ data.title }}</h5>
              <p class="mt-1 font-proximaLight text-sm">{{ data.description }}</p>
              <TagsComponent :tags="data.diary_tags" />
              <div class="w-10/12 lg:w-full flex items-center mt-4 font-proximaMedium text-xs">
                <span>{{dateDairy(data)  }}</span>
                <span class="mx-6">{{ timeDairy(data) }}</span>
                <div class="flex items-center">
                  <span class="mr-2">Mood:</span>
                 <!--  <components :is="data.mood" /> -->
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- :petsOwned="petsOwned" -->
    <AddNoteModals :diary="true" :pet="this.$route.params.data" />
    <!-- :petsOwned="petsOwned" -->
    <EditNoteModals :toggleEditModals="toggleEditModals" :data="data" :id="id" :pets="pets" :title="title"
      :description="description" :mood="mood" :keyword="keyword"  :relevantPets="relevantPets"
      @closeEditModals="closeEditModals" />
  </div>
</template>

<script>
import moment from "moment";
import HeaderGlobal from "../../header/HeaderGlobal.vue";
import TagsComponent from "../view/TagsComponent.vue";
import PetImagesComponent from "./PetImagesComponent.vue";
import EditIcon from "../../icons/EditIcon.vue";
import EmojiAngry from "../../icons/emojiColor/EmojiAngry.vue";
import EmojiHeartEyes from "../../icons/emojiColor/EmojiHeartEyes.vue";
import EmojiLaugh from "../../icons/emojiColor/EmojiLaugh.vue";
import EmojiMeh from "../../icons/emojiColor/EmojiMeh.vue";
import EmojiSad from "../../icons/emojiColor/EmojiSad.vue";
import EmojiSadSlight from "../../icons/emojiColor/EmojiSadSlight.vue";
import EmojiSmile from "../../icons/emojiColor/EmojiSmile.vue";
import AddNoteModals from "../../careblog/modals/AddNoteModals.vue";
import EditNoteModals from "../../careblog/modals/EditNoteModals.vue";

import DataserviceLogo from "../../DataserviceLogo.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    HeaderGlobal,
    TagsComponent,
    PetImagesComponent,
    DataserviceLogo,
    EditIcon,
    EmojiAngry,
    EmojiHeartEyes,
    EmojiLaugh,
    EmojiMeh,
    EmojiSad,
    EmojiSadSlight,
    EmojiSmile,
    AddNoteModals,
    EditNoteModals
  },
  data() {
    return {
      showLoader: false,

      toggleEditModals: false,

      toggleEditModals: false,
      title: "",
      description: "",
      relevantPets: [],
      mood: 0,
      keyword: [],
      data: {},
      id: 0,
    };
  },
  computed: {
    ...mapGetters({
      petRecords: "petRecord/getPetRecords",
      careBlogs: "appointment/getCareBlogs",
    }),

 
     pets() {
      var pts = [];
      this.careBlogs.map(item => item.pets_profiles.map( pet=> (pts.findIndex(x => x.petId==pet.petId) === -1 ? pts.push(pet) : "")))
      return pts;
    },
 
    contacts() {
      return this.petRecords
        ? this.petRecords.filter(
          (item) => item.type == 1 || item.type == 2 || item.type == 3
        )
        : [];
    },
  },
  async created() {
    this.showLoader = true;
    this.getCareBlogsByUser();

    this.showLoader = false;
  },
  methods: {
    ...mapActions({
      getPetRecords: "petRecord/getPetRecords",
      getCareBlogsByUser :"appointment/getCareBlogsByUser",
    }),
    

    tabs(i) {
      this.whichTab = i;
    },
    openEditModals(i) {
      this.data = i;
      this.title = i.title;
      this.description = i.description;
      this.relevantPets = i.relevantPets;
      this.mood = i.mood;
      this.keyword = i.diary_tags;
      this.toggleEditModals = true;
      this.id = i.id;
    },
    closeEditModals() {
      this.toggleEditModals = false;
    },
    dateDairy(data) {
      return moment(String(data.date_created)).format("dddd, DD MMM YYYY");
    },
    timeDairy(data) {
      return moment(String(data.date_created)).format("hh:mm a");
    },
  },
};
</script>

<style>

</style>