<template>
  <div v-if="diaryDashboard" class="w-full px-4 lg:px-0 lg:w-10/12 font-proximaMedium">
    <h2 class="mt-8 mb-4 text-left text-2xl font-bold text-subheaderText">Your Dashboard</h2>
    <ul class="w-full px-2 lg:px-14 py-6 bg-white rounded-md flex items-center justify-between">
      <li class="flex flex-col-reverse lg:flex-col-reverse lg:flex-col items-center justify-center">
        <div class="w-20 h-20 p-2 bg-primary rounded-full">
          <div class="w-16 h-16 bg-primary rounded-full border border-white flex items-center justify-center text-2xl text-white font-bold">
            {{ diaryDashboard.current_streak }}
          </div>
        </div>
        <span class="text-sm mb-2 lg:mb-0">Current Streak</span>
      </li>
      <li class="flex flex-col-reverse lg:flex-col items-center justify-center">
        <div class="w-20 h-20 p-2 bg-primary rounded-full">
          <div class="w-16 h-16 bg-primary rounded-full border border-white flex items-center justify-center text-2xl text-white font-bold">
            {{ diaryDashboard.best_streak }}
          </div>
        </div>
        <span class="text-sm mb-2 lg:mb-0">Best Streak</span>
      </li>
      <li class="flex flex-col-reverse lg:flex-col items-center justify-center cursor-pointer" @click="gotoAllentries">
        <div class="w-20 h-20 p-2 bg-primary rounded-full">
          <div class="w-16 h-16 bg-primary rounded-full border border-white flex items-center justify-center text-2xl text-white font-bold">
            {{ diaryDashboard.number_of_entries }}
          </div>
        </div>
        <span class="text-sm mb-2 lg:mb-0">No. of Entries</span>
      </li>
    </ul>
  </div>
  <div v-else class="w-full px-4 lg:px-0 lg:w-10/12 font-proximaMedium">
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters({
      diaryDashboard: "appointment/getDiaryDashboard",
    }),
  },
  mounted() {
    this.getDiaryDashboard();
  },
  methods: {
    ...mapActions({
      getDiaryDashboard: "appointment/getDiaryDashboard",
    }),
    gotoAllentries() {
      this.$router.push({ path: "/petownerdiary/allentries" });
    },
  },
};
</script>

<style></style>
