<template>
  <Transition name="modal">
    <div v-if="toggleEditModals" class="modal-mask">
      <div class="modal-wrapper">
        <div
          class="
            modal-container
            md:w-10/12
            lg:w-6/12
            2xl:w-5/12
            flex flex-col
            justify-center
            items-center
            font-proximaMedium
            overflow-y-auto
            relative
          "
        >
          <div
            class="
              absolute
              top-3
              right-6
              text-2xl
              font-proximaSemibold
              cursor-pointer
            "
            @click="closeEditNoteModals"
          >
            ✖
          </div>
          <div class="">
            <img
              class="m-auto"
              ref="prefetch"
              src="@/assets/images/diary-bg-icon.png"
              alt="diary-icon"
            />
            <h1 class="mt-4 text-2xl">
              {{ $t("edit_note_diary_modals_title") }}
            </h1>
          </div>
          <div class="mt-8 w-full">
            <div
              class="w-full p-2 text-left border-2 border-primary rounded-md"
            >
              <span
                
                class="text-lightGrey"
                @click="togglePets = !togglePets"
                >{{ $t("global_select_relevant_pets") }}</span
              >
              <ul  class="w-full flex justify-start flex-wrap">
                <li
                  class="
                    w-max
                    mr-2
                    mt-2
                    text-white
                    bg-primary
                    rounded-md
                    px-3
                    py-2
                    relative
                  "
                  v-for="(data, i) in concatPets"
                  :key="i"
                >
                  {{ data.callName }}
                  <div
                    class="
                      bg-white
                      rounded-full
                      absolute
                      -right-1
                      -top-1
                      cursor-pointer
                    "
                  >
                    <TrashIcon iconColor="#0AC0CB" />
                  </div>
                </li>
              </ul>
            </div>
            <ul
              v-show="togglePets"
              class="bg-lightGrey20 flex flex-wrap justify-evenly rounded-md"
            >
              <PetOwnedList
                v-for="(data, i) in pets"
                :key="i"
                :data="data"
                @addPet="funcAddPet(data.callName)"
                @removePet="funcRemovePet(data.callName)"
              />
            </ul>
          </div>
          <div class="w-full mt-4">
            <h5 class="text-left">{{ $t("global_title") }}</h5>
            <input
              class="w-full p-2 border-2 border-primary rounded-md"
              type="text"
              v-model="title"
            />
          </div>
          <div class="w-full mt-4">
            <h5 class="text-left">{{ $t("global_description") }}</h5>
            <textarea
              class="w-full p-2 border-2 border-primary rounded-md"
              cols="30"
              rows="5"
              v-model="description"
            ></textarea>
          </div>
          <div class="mt-4 w-full flex items-center justify-start">
            <EmojiEach
              v-for="(emoji, i) in emojis"
              :key="i"
              :eachEmoji="i"
              :highlightedEmoji="mood"
              :whichEmoji="selectedEmojiName"
              @click.native="clickIcon(emoji, i)"
            />
          </div>
          <div class="w-full mt-4">
            <vue-tags-input
              v-model="tag"
              :tags="propTags"
              :add-on-key="[',']"
              @tags-changed="(newTags) => (tags = newTags)"
            />
          </div>
          <div class="mt-12 w-8/12 flex justify-evenly">
            <div class="mx-auto w-10/12 md:w-5/12 relative">
              <BorderedButton
                class="mx-auto"
                marginT="mt-2"
                title="Delete"
                paddingX="px-6"
                width="w-full"
                borderColor="border-red"
                textCl="text-red"
                @click.native="toggleDeleteModals = true"
              />
            </div>
            <div class="mx-auto w-10/12 md:w-5/12 relative">
              <SubmitButton
                class="mx-auto"
                marginT="mt-2"
                :title="$t('global_confirm')"
                background="bg-success"
                paddingX="px-6"
                width="w-full"
                @click.native="closeEditNoteModals"
              />
            </div>
          </div>
        </div>
      </div>
      <ModalsConfirm
        :show="toggleDeleteModals"
        @close="toggleDeleteModals = false"
      >
        <div slot="actionQuestion" class="relative">
          <div
            class="
              absolute
              -top-6
              -right-6
              text-lg
              font-proximaSemibold
              cursor-pointer
            "
            @click="toggleDeleteModals = false"
          >
            ✖
          </div>

          <h1 class="mt-4 text-2xl">
            {{ $t("edit_note_diary_modals_delete_entry") }}
          </h1>
        </div>
        <div
          slot="actionText"
          class="
            mb-6
            flex flex-col
            items-center
            justify-center
            text-sm
            font-proximaLight
          "
        >
          <p>
            <span class="text-red">{{ $t("global_attention") }}</span
            >{{ $t("edit_note_diary_modals_delete_message") }}
          </p>
        </div>
        <div slot="leftBtn" class="mx-auto w-10/12 relative">
          <BorderedButton
            class="mx-auto"
            marginT="mt-2"
            :title="$t('global_cancel')"
            paddingX="px-4"
            width="w-full"
            @click.native="toggleDeleteModals = false"
          />
        </div>
        <div slot="rightBtn" class="mx-auto w-10/12 relative">
          <SubmitButton
            class="mx-auto"
            marginT="mt-2"
            :title="$t('global_confirm')"
            background="bg-red"
            paddingX="px-4"
            width="w-full"
            @click.native="deleteBlog"
          />
        </div>
      </ModalsConfirm>
    </div>
  </Transition>
</template>

<script>
import SubmitButton from "../../buttons/SubmitButton.vue";
import BorderedButton from "../../buttons/BorderedButton.vue";
import PetOwnedList from "./PetOwnedList.vue";
import VueTagsInput from "@johmun/vue-tags-input";
import ModalsConfirm from "../../modals/ModalsConfirm.vue";

import TrashIcon from "@/components/icons/TrashIcon.vue";
import EmojiEach from "../../emojis/EmojiEach.vue";
import { mapActions } from "vuex";

export default {
  props: {
    toggleEditModals: Boolean,
    petsOwned: Array,
    pets: Array,
    title: String,
    description: String,
    mood: Number,
    keyword: Array,
    id: Number,
    data: {},
  },
  components: {
    SubmitButton,
    BorderedButton,
    PetOwnedList,
    VueTagsInput,
    ModalsConfirm,
    TrashIcon,
    EmojiEach,
  },
  data() {
    return {
      toggleDeleteModals: false,
      togglePets: false,
      checkedPet: [],
      mergedPets: [],
      selectedEmojId: this.mood,
      selectedEmojiName: "",
      tag: "",
      tags:[],
      emojis: [
        {
          id: "1",
          name: "EmojiHeartEyes",
        },
        {
          id: "2",
          name: "EmojiLaugh",
        },
        {
          id: "3",
          name: "EmojiSmile",
        },
        {
          id: "4",
          name: "EmojiSadSlight",
        },
        {
          id: "5",
          name: "EmojiMeh",
        },
        {
          id: "6",
          name: "EmojiSad",
        },
        {
          id: "7",
          name: "EmojiAngry",
        },
      ],
    };
  },
  computed: {
    compAddDiaryModals() {
      return this.$store.state.careBlogDiaryModalsState;
    },
    propTags() {
      //this.keyword?this.keyword.map(a=>this.tags.push(a)):"";

      return this.keyword;
    },
    concatPets() {
      console.log(this.pets);
      return this.pets;
      // this.mergedPets = pets;
    },
  },
  methods: {
    ...mapActions({ deleteCareBlog: "appointment/deleteCareBlog" }),
    funcAddPet(i) {
      console.log(i);
      this.checkedPet.push(i);
    },
    funcRemovePet(i) {
      let item = i;

      const index = this.checkedPet.indexOf(item);
      this.checkedPet.splice(index, 1);
    },
    removeSelectedPet(i) {
      let item = i;

      const index = this.mergedPets.indexOf(item);
      this.mergedPets.splice(index, 1);
    },
    clickIcon(emoji, i) {
      this.selectedEmojId = i;
      this.selectedEmojiName = emoji.filter((e) => e.id == i).name;
      console.log(selectedEmojiName);
    },
    closeEditNoteModals() {
      this.$store.commit("careBlogDiaryModals", false);
      this.$emit("closeEditModals");
    },

    deleteBlog() {
      try {
        this.deleteCareBlog(this.data);
        this.toggleDeleteModals = false;
        this.closeEditNoteModals();
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss">
.vue-tags-input {
  max-width: 100% !important;
}

.vue-tags-input .ti-input {
  border: 2px solid #0ac0cb;
  padding: 6px 4px;
  border-radius: 0.375rem;
}

.ti-tag {
  padding: 8px !important;
  border-radius: 0.375rem !important;
  background-color: #0ac0cb !important;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  // width: 600px;
  margin: 0px auto;
  padding: 30px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
