<template>
  <div class="mt-2">
    <span class="mr-1 text-sm text-white font-proximaLight bg-primary rounded-sm px-2 py-1" v-for="(data, i) in tags" :key="i">
        {{ data }}
    </span>
  </div>
</template>

<script>
export default {
    props: ["tags"]
};
</script>

<style>
</style>