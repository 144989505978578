<template>
  <ul class="w-20 flex flex-wrap absolute top-4 right-1">
    <li v-for="(data, i) in images" :key="i">
        <img class="h-8 w-8 mr-2 mb-1 rounded-full" :src="data.picture ? data.picture : require('@/assets/images/peticons/' + 'dog_icon' + '.png')" :alt="data">
    </li>
  </ul>
</template>

<script>
export default {
    props: ["images"]
};
</script>

<style>
</style>