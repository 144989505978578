<template>
  <div class="my-6">
    <AllentriesSection />
    <NavMenuSecondary page="PetownerDiaryView" />
  </div>
</template>

<script>
import AllentriesSection from "../components/petownerdiary/allentries/AllentriesSection.vue";
import NavMenuSecondary from "../components/navigationmenu/secondary/NavMenuSecondary.vue";

export default {
  components: {
    AllentriesSection,
    NavMenuSecondary
  },
};
</script>