<template>
  <div>
    <img
      class="mt-4 mb-10 m-auto"
      src="@/assets/images/diary-page-icon.png"
      alt="diary-page-icon"
      v-if="!careBlogs"
    />
    <div v-else>
      <ul>
        <li
          class="m-6 bg-whiteBlue p-4 lg:pr-20 text-left relative"
          v-for="(data, i) in careBlogs"
          :key="i"
        >
          <FavoriteComponent :f="data.favourite"/>
          <EditIcon
            class="absolute bottom-4 right-4 cursor-pointer"
            iconColor="#0AC0CB"
            @click.native="openEditModals(data)"
          />
          <h5 class="font-proximaMedium">{{ data.title }}</h5>
          <p class="mt-1 font-proximaLight text-sm">{{ data.description }}</p>
          
          <TagsComponent :tags="data.diary_tags" />
          <div class="w-10/12 lg:w-full flex items-center mt-4 font-proximaMedium text-xs">
            <span>{{ dateDairy(data) }}</span>
            <span class="mx-6">{{timeDairy(data) }}</span>
            <div class="flex items-center">
              <span class="mr-2">Mood:</span>
             <!--  <component :is="whichIcon(data)" width="20" height="20" class="ml-2" /> -->
            </div>
          </div>
        </li>
      </ul>
      <img
        class="mx-auto mb-6 cursor-pointer"
        src="@/assets/images/expand-icon.png"
        alt="expand-icon"
        @click="gotoAllentries"
      />
    </div>
    <EditNoteModals
      :toggleEditModals="toggleEditModals"
      :data="data"
      :id="id"
      :pets="pets"
      :title="title"
      :description="description"
      :mood="mood"
      :keyword="keyword"
      :petsOwned="petsOwned"
      :relevantPets="relevantPets"
      @closeEditModals="closeEditModals"
    />
  </div>
</template>

<script>
import moment from "moment";
import TagsComponent from "./TagsComponent.vue";
import FavoriteComponent from "./FavoriteComponent.vue";
import EditIcon from "../../icons/EditIcon.vue";
import EmojiAngry from "../../icons/emojiColor/EmojiAngry.vue";
import EmojiHeartEyes from "../../icons/emojiColor/EmojiHeartEyes.vue";
import EmojiLaugh from "../../icons/emojiColor/EmojiLaugh.vue";
import EmojiMeh from "../../icons/emojiColor/EmojiMeh.vue";
import EmojiSad from "../../icons/emojiColor/EmojiSad.vue";
import EmojiSadSlight from "../../icons/emojiColor/EmojiSadSlight.vue";
import EmojiSmile from "../../icons/emojiColor/EmojiSmile.vue";
import EditNoteModals from "../../careblog/modals/EditNoteModals.vue";



export default {
  props:["careBlogs"],
  components: {
    TagsComponent,
    FavoriteComponent,
    EditIcon,
    EmojiAngry,
    EmojiHeartEyes,
    EmojiLaugh,
    EmojiMeh,
    EmojiSad,
    EmojiSadSlight,
    EmojiSmile,
    EditNoteModals
  },
  data() {
    return {
      petsOwned: [
        { name: "Rocky Rocky", image: "dog_icon" },
        { name: "Milo", image: "other_icon" },
        { name: "Buddy", image: "cat_icon" },
        { name: "Bear", image: "ferret_icon" },
      ],
    
      pets: [],
      toggleEditModals: false,
      title: "",
      description: "",
      relevantPets: [],
      mood: 0,
      keyword: [],
      data: {},
      id: 0,
    };
  },
  computed: {
    whichIcon(data) {
      if (data.mood == 1) return "EmojiHeartEyes";
      else if (data.mood == 2) return "EmojiLaugh";
      else if (data.mood == 3) return "EmojiSmile";
      else if (data.mood == 4) return "EmojiSadSlight";
      else if (data.mood == 5) return "EmojiMeh";
      else if (data.mood == 6) return "EmojiSad";
      else if (data.mood == 7) return "EmojiAngry";
    },
    expandEntry() {
      if (this.expanded) {
        return "lg:w-full";
      } else {
        return "lg:w-49perc";
      }
    },
   
  },
  mounted(){console.log(this.careBlogs);},
  methods: {
    dateDairy(data) {
      return moment(String(data.date_created)).format("dddd, DD MMM YYYY");
    },
    timeDairy(data) {
      return moment(String(data.date_created)).format("hh:mm a");
    },
    gotoAllentries() {
      this.$router.push({
          name: "PetownerDiaryAllentries",
          params: { data: this.careBlogs },
        });
     
    },
    openEditModals(i) {
      this.data = i;
      this.pets = i.tags;
      this.title = i.name;
      this.description = i.text;
      this.relevantPets = i.relevantPets;
      this.mood = i.mood;
      this.keyword = i.tags;
      this.toggleEditModals = true;
      this.id = i.id;
    },
    closeEditModals() {
      this.toggleEditModals = false;
    },
  },
};
</script>

<style>
</style>