<template>
  <div
    v-if="diaryDashboard"
    class="w-full lg:w-10/12 px-4 flex flex-col lg:flex-row items-start justify-between"
  >
    <div class="w-full lg:w-6/12 mr-0 lg:mr-2 font-proximaMedium">
      <h2 class="mt-8 mb-4 text-left text-2xl font-bold text-subheaderText">
        Attachments
      </h2>
      <ul
        class="w-full h-40 px-12 py-4 bg-white rounded-md flex items-center justify-between"
      >
        <li class="flex flex-col items-center justify-between">
          <img
            src="@/assets/images/audio-file-icon.png"
            alt="audio-file-icon"
          />
          <div
            class="w-10 h-10 bg-primary rounded-full flex items-center justify-center text-lg text-white font-bold"
          >
            {{ diaryDashboard.diary_voice_count }}
          </div>
          <span>Audio</span>
        </li>
        <li class="flex flex-col items-center justify-between">
          <img
            src="@/assets/images/camera-line-icon.png"
            alt="camera-line-icon"
          />
          <div
            class="w-10 h-10 bg-primary rounded-full flex items-center justify-center text-lg text-white font-bold"
          >
            {{ diaryDashboard.diary_video_count }}
          </div>
          <span>Video</span>
        </li>
        <li class="flex flex-col items-center justify-between">
          <img
            class="mt-2"
            src="@/assets/images/image-gallery-icon.png"
            alt="image-galery-icon"
          />
          <div
            class="w-10 h-10 mt-3 bg-primary rounded-full flex items-center justify-center text-lg text-white font-bold"
          >
            {{ diaryDashboard.diary_image_count }}
          </div>
          <span>Galery</span>
        </li>
      </ul>
    </div>

    <div class="w-full lg:w-6/12 ml-0 lg:ml-2 font-proximaMedium">
      <h2 class="mt-8 mb-4 text-left text-2xl font-bold text-subheaderText">
        Appointments
      </h2>
      <div class="w-full h-40 px-8 py-4 bg-white rounded-md overflow-y-auto">
        <ul class="mb-2 text-sm flex items-center justify-between">
          <li class="w-3/12">Time</li>
          <li class="w-3/12">Date</li>
          <li class="w-4/12">Location</li>
          <li class="w-2/12">Tag</li>
        </ul>
        <ul
          class="mb-2 pb-1 text-sm font-proximaLight flex items-center justify-between border-b border-primary"
          v-for="(data, i) in appointments"
          :key="i"
        >
          <li class="w-3/12">{{ data.time_appointment }}</li>
          <li class="w-3/12">{{ data.date_appointment }}</li>
          <li class="w-4/12">
            {{
              data.location_appointment_lat +
              " " +
              data.location_appointment_long
            }}
          </li>
          <li class="w-2/12">{{ data.description }}</li>
        </ul>
      </div>
    </div>
  </div>
  <div
    v-else
    class="w-full lg:w-10/12 px-4 flex flex-col lg:flex-row items-start justify-between"
  ></div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      diaryDashboard: "appointment/getDiaryDashboard",
      appointments: "appointment/getAppointments",
    }),
  },
  created() {
    this.getDiaryDashboard();
    this.getAppointmentsByUser();
  },
  methods: {
    ...mapActions({
      getDiaryDashboard: "appointment/getDiaryDashboard",
      getAppointmentsByUser: "appointment/getAppointmentsByUser",
    }),
  },
};
</script>

<style></style>
