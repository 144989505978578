<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 18.75C7.67936 18.75 5.45376 17.8281 3.81282 16.1872C2.17187 14.5462 1.25 12.3206 1.25 10C1.25 7.67936 2.17187 5.45376 3.81282 3.81282C5.45376 2.17187 7.67936 1.25 10 1.25C12.3206 1.25 14.5462 2.17187 16.1872 3.81282C17.8281 5.45376 18.75 7.67936 18.75 10C18.75 12.3206 17.8281 14.5462 16.1872 16.1872C14.5462 17.8281 12.3206 18.75 10 18.75ZM10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 7.34784 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0C7.34784 0 4.8043 1.05357 2.92893 2.92893C1.05357 4.8043 0 7.34784 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C4.8043 18.9464 7.34784 20 10 20Z"
      :fill="iconColor"
    />
    <path
      d="M14.1438 12.5179C14.2625 12.4899 14.3867 12.4973 14.5012 12.5391C14.6157 12.5809 14.7155 12.6552 14.7884 12.753C14.8612 12.8508 14.9038 12.9677 14.911 13.0894C14.9181 13.2111 14.8896 13.3322 14.8288 13.4379C14.3352 14.2933 13.625 15.0036 12.7696 15.4974C11.9143 15.9911 10.9439 16.2508 9.95631 16.2504C8.96868 16.2508 7.99835 15.9911 7.14299 15.4974C6.28763 15.0036 5.57741 14.2933 5.08381 13.4379C5.02303 13.3322 4.99447 13.2111 5.00166 13.0894C5.00885 12.9677 5.05147 12.8508 5.12427 12.753C5.19707 12.6552 5.29687 12.5809 5.41139 12.5391C5.52591 12.4973 5.65015 12.4899 5.76881 12.5179H5.77506L5.79631 12.5241L5.88006 12.5429L6.19506 12.6116C6.46381 12.6691 6.83881 12.7466 7.26631 12.8229C8.13256 12.9779 9.16881 13.1254 9.95631 13.1254C10.7438 13.1254 11.7813 12.9779 12.6463 12.8229C13.1103 12.7394 13.5725 12.646 14.0326 12.5429L14.1163 12.5241L14.1376 12.5191L14.1438 12.5166V12.5179ZM5.94506 5.70787C6.89881 3.92787 10.9701 5.55787 7.13506 10.0004C1.51506 8.00537 4.19756 4.62787 5.94506 5.70787ZM14.0551 5.70787C15.8026 4.62787 18.4851 8.00537 12.8651 10.0004C9.03131 5.55787 13.1026 3.92787 14.0551 5.70787Z"
      :fill="iconColor"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 26,
    },
    height: {
      type: [Number, String],
      default: 26,
    },
    iconColor: {
      type: String,
      default: "#c4c4c4",
    },
  },
};
</script>