<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.15"
      d="M4.95312 22.75C4.95313 22.9489 5.79598 23.1397 7.29627 23.2803C8.79656 23.421 10.8314 23.5 12.9531 23.5C15.0749 23.5 17.1097 23.421 18.61 23.2803C20.1103 23.1397 20.9531 22.9489 20.9531 22.75C20.9531 22.5511 20.1103 22.3603 18.61 22.2197C17.1097 22.079 15.0749 22 12.9531 22C10.8314 22 8.79656 22.079 7.29627 22.2197C5.79598 22.3603 4.95313 22.5511 4.95312 22.75Z"
      fill="#45413C"
    />
    <path
      d="M2.95312 10.75C2.95313 13.4022 4.00669 15.9457 5.88206 17.8211C7.75742 19.6964 10.301 20.75 12.9531 20.75C15.6053 20.75 18.1488 19.6964 20.0242 17.8211C21.8996 15.9457 22.9531 13.4022 22.9531 10.75C22.9531 8.09784 21.8996 5.5543 20.0242 3.67893C18.1488 1.80357 15.6053 0.75 12.9531 0.75C10.301 0.75 7.75742 1.80357 5.88206 3.67893C4.00669 5.5543 2.95313 8.09784 2.95312 10.75Z"
      fill="#FFE500"
    />
    <path
      d="M12.9531 0.75C10.9753 0.75 9.04192 1.33649 7.39743 2.4353C5.75294 3.53412 4.47121 5.0959 3.71433 6.92317C2.95746 8.75043 2.75942 10.7611 3.14528 12.7009C3.53113 14.6407 4.48354 16.4225 5.88206 17.8211C7.28059 19.2196 9.06242 20.172 11.0022 20.5579C12.942 20.9437 14.9527 20.7457 16.78 19.9888C18.6072 19.2319 20.169 17.9502 21.2678 16.3057C22.3666 14.6612 22.9531 12.7278 22.9531 10.75C22.9531 8.09784 21.8996 5.5543 20.0242 3.67893C18.1488 1.80357 15.6053 0.75 12.9531 0.75ZM12.9531 19.25C11.1484 19.25 9.38415 18.7148 7.88355 17.7122C6.38295 16.7095 5.21338 15.2844 4.52273 13.617C3.83208 11.9496 3.65137 10.1149 4.00346 8.3448C4.35555 6.57472 5.22463 4.9488 6.50078 3.67265C7.77693 2.3965 9.40285 1.52742 11.1729 1.17533C12.943 0.823244 14.7777 1.00395 16.4451 1.6946C18.1125 2.38525 19.5376 3.55482 20.5403 5.05542C21.543 6.55602 22.0781 8.32025 22.0781 10.125C22.0781 12.5451 21.1167 14.8661 19.4055 16.5773C17.6942 18.2886 15.3732 19.25 12.9531 19.25Z"
      fill="#EBCB00"
    />
    <path
      d="M5.17486 7.21392C5.31551 7.35458 5.6739 7.26597 6.17118 6.9676C6.66846 6.66924 7.2639 6.18554 7.82651 5.62293C8.38912 5.06032 8.87281 4.46488 9.17118 3.9676C9.46955 3.47032 9.55815 3.11194 9.4175 2.97128C9.27685 2.83063 8.91846 2.91924 8.42118 3.2176C7.9239 3.51597 7.32846 3.99966 6.76585 4.56227C6.20324 5.12488 5.71955 5.72032 5.42118 6.2176C5.12281 6.71488 5.03421 7.07327 5.17486 7.21392Z"
      fill="#FFF48C"
    />
    <path
      d="M2.95312 10.75C2.95313 13.4022 4.00669 15.9457 5.88206 17.8211C7.75742 19.6964 10.301 20.75 12.9531 20.75C15.6053 20.75 18.1488 19.6964 20.0242 17.8211C21.8996 15.9457 22.9531 13.4022 22.9531 10.75C22.9531 8.09784 21.8996 5.5543 20.0242 3.67893C18.1488 1.80357 15.6053 0.75 12.9531 0.75C10.301 0.75 7.75742 1.80357 5.88206 3.67893C4.00669 5.5543 2.95313 8.09784 2.95312 10.75V10.75Z"
      stroke="#45413C"
      stroke-width="0.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M18.4542 4.91533C18.7492 5.21033 18.5892 5.84033 18.0992 6.33033C17.6092 6.82033 16.9542 7.00033 16.6642 6.68533C16.3742 6.37033 16.5342 5.76033 17.0192 5.27033C17.5042 4.78033 18.1392 4.62533 18.4542 4.91533Z"
      fill="#FFAA54"
    />
    <path
      d="M18.7737 16.5706C16.0237 19.3206 11.8587 19.7006 9.07368 17.5706C8.99027 17.4999 8.92442 17.4109 8.88133 17.3105C8.83824 17.21 8.81909 17.1009 8.82539 16.9918C8.8317 16.8827 8.86328 16.7766 8.91765 16.6818C8.97202 16.587 9.04769 16.5061 9.13868 16.4456C12.7882 13.7894 16.0017 10.5809 18.6637 6.93555C18.7242 6.84456 18.8051 6.7689 18.8999 6.71453C18.9947 6.66016 19.1008 6.62857 19.21 6.62227C19.3191 6.61596 19.4281 6.63511 19.5286 6.6782C19.629 6.72129 19.7181 6.78714 19.7887 6.87055C21.9037 9.65555 21.5237 13.8206 18.7737 16.5706Z"
      fill="#FFB0CA"
      stroke="#45413C"
      stroke-width="0.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M16.4034 14.2003C17.7223 12.8071 19.3442 11.7361 21.1434 11.0703C21.0843 13.1389 20.2361 15.1064 18.7729 16.5697C17.3096 18.033 15.342 18.8812 13.2734 18.9403C13.9392 17.1411 15.0102 15.5192 16.4034 14.2003Z"
      fill="#FF87AF"
      stroke="#45413C"
      stroke-width="0.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.9991 3.5L13.4541 5.22C13.4257 5.30769 13.422 5.40155 13.4435 5.4912C13.465 5.58086 13.5109 5.66281 13.5761 5.72801C13.6413 5.7932 13.7233 5.8391 13.8129 5.86063C13.9026 5.88216 13.9964 5.87848 14.0841 5.85L15.7891 5.285"
      stroke="#45413C"
      stroke-width="0.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.11858 16.2294C7.41358 16.5244 8.04358 16.3644 8.53358 15.8744C9.02358 15.3844 9.17858 14.7544 8.88858 14.4594C8.59858 14.1644 7.95358 14.3294 7.45358 14.8144C6.95358 15.2994 6.82858 15.9344 7.11858 16.2294Z"
      fill="#FFAA54"
    />
    <path
      d="M5.71875 11.7965L7.42375 11.2315C7.51144 11.203 7.6053 11.1993 7.69495 11.2209C7.78461 11.2424 7.86656 11.2883 7.93176 11.3535C7.99695 11.4187 8.04285 11.5006 8.06438 11.5903C8.08591 11.6799 8.08223 11.7738 8.05375 11.8615L7.48875 13.5665"
      stroke="#45413C"
      stroke-width="0.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9.13868 16.4606C10.9601 15.1307 12.6768 13.663 14.2737 12.0706C15.8661 10.4736 17.3338 8.75694 18.6637 6.93555C18.7242 6.84456 18.8051 6.7689 18.8999 6.71453C18.9947 6.66016 19.1008 6.62857 19.21 6.62227C19.3191 6.61596 19.4281 6.63511 19.5286 6.6782C19.629 6.72129 19.7181 6.78714 19.7887 6.87055C19.8887 7.00055 19.9787 7.14055 20.0687 7.28055C18.6201 9.32923 17.01 11.2587 15.2537 13.0506C13.4618 14.8069 11.5324 16.417 9.48368 17.8656C9.34368 17.7756 9.20368 17.6856 9.07368 17.5856C8.99027 17.5149 8.92442 17.4259 8.88133 17.3255C8.83824 17.225 8.81909 17.1159 8.82539 17.0068C8.8317 16.8977 8.86328 16.7916 8.91765 16.6968C8.97202 16.602 9.04769 16.5211 9.13868 16.4606Z"
      fill="white"
      stroke="#45413C"
      stroke-width="0.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 26,
    },
    height: {
      type: [Number, String],
      default: 26,
    },
    iconColor: {
      type: String,
      default: "#c4c4c4",
    },
  },
};
</script>